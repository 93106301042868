import React, { useState, useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import {
  deleteDoc,
  doc,
  getDoc,
  query,
  where,
  collection,
  getDocs,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import { Box, Divider } from "@chakra-ui/react";

export default function DisplayPreported({
  publicationId,
  userId,
  reportUserId,
}) {
  const [basicModal, setBasicModal] = useState(false);
  const [publication, setPublication] = useState({ date: "", body: "" });
  const [userData, setUserData] = useState({ role: "" });

  const toggleOpen = () => setBasicModal(!basicModal);

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setUserData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      }
    };

    const fetchPublication = async () => {
      try {
        const publicationDoc = await getDoc(doc(db, "projects", publicationId));
        if (publicationDoc.exists()) {
          const publicationData = publicationDoc.data();
          setPublication({
            date: publicationData.publicationDate,
            body: publicationData.body,
          });
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
    };

    fetchUserData();
    if (publicationId) {
      fetchPublication();
    }
  }, [publicationId]);

  const handleDelete = async () => {
    if (userData.role !== "support") {
      console.error("Unauthorized: Only support role can perform this action");
      return;
    }

    try {
      await deleteDoc(doc(db, "projects", publicationId));
      // 7. Decrement the user's MProjects count
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const currentMProjects = userSnap.data().MProjects ?? 0;
        const newMProjects = currentMProjects > 0 ? currentMProjects - 1 : 0;
        await updateDoc(userRef, { MProjects: newMProjects });
      }

      const reportsQuery = query(
        collection(db, "reports"),
        where("publicationId", "==", publicationId)
      );

      const reportSnapshots = await getDocs(reportsQuery);

      reportSnapshots.forEach(async (reportDoc) => {
        await addDoc(collection(db, "notificationsSended"), {
          projectId: publicationId,
          userId: "master",
          checked: true,
          to: userId,
        });
        await deleteDoc(doc(db, "reports", reportDoc.id));
      });

      toggleOpen();
    } catch (error) {
      console.error("Error deleting publication and report: ", error);
    }
  };
  const handleCancelDelete = async () => {
    if (userData.role !== "support") {
      console.error("Unauthorized: Only support role can perform this action");
      return;
    }

    try {
      const reportsQuery = query(
        collection(db, "reports"),
        where("publicationId", "==", publicationId)
      );

      const reportSnapshots = await getDocs(reportsQuery);

      reportSnapshots.forEach(async (reportDoc) => {
        await addDoc(collection(db, "notificationsSended"), {
          projectId: publicationId,
          userId: "master",
          checked: false,
          to: reportUserId,
        });
        await deleteDoc(doc(db, "reports", reportDoc.id));
      });

      toggleOpen();
    } catch (error) {
      console.error("Error deleting publication and report: ", error);
    }
  };

  return (
    <>
      <MDBBtn
        outline
        color="danger"
        size="sm"
        type="button"
        onClick={toggleOpen}
        style={{ fontSize: "8px" }}
      >
        PUBLICATION REPORTED
      </MDBBtn>
      <MDBModal
        open={basicModal}
        onClose={() => setBasicModal(false)}
        tabIndex="-1"
      >
        <MDBModalDialog scrollable>
          <MDBModalContent className="notificationsContentModal">
            <MDBModalHeader className="notificationsContentHeaderModal">
              <MDBModalTitle>
                {new Date(publication.date).toLocaleDateString("en-US", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}
              </MDBModalTitle>
              <MDBBtn
                color="light"
                className="btn-close"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <Box className="notificationsDivider">
              <Divider orientation="horizontal" borderColor="transparent" />
            </Box>
            <MDBModalBody>{publication.body}</MDBModalBody>
            <Box className="notificationsDivider">
              <Divider orientation="horizontal" borderColor="transparent" />
            </Box>
            <MDBModalFooter style={{ border: "none" }}>
              <MDBBtn color="danger" onClick={handleDelete}>
                Delete
              </MDBBtn>
              <MDBBtn color="dark" onClick={handleCancelDelete}>
                Cancel
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
