import React, { useState } from "react";
import { MDBContainer, MDBModalTitle, MDBTypography } from "mdb-react-ui-kit";
import Header from "../contents/header";
import Table from "../masterFL/table";
import useCurrentUser from "../currentUser/currentuser";
import Chart1, { Chart3 } from "../masterFL/charts";
import { Chart2 } from "../masterFL/charts";
import {
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import ShapeSection from "./shapeSection";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { BsOpencollective } from "react-icons/bs";

import MyPieChart from "../masterFL/mypiechart";
import MyDoughnutChart from "../masterFL/mydoughnutchart";
import ProjectList from "../projectList/projectList";
import ReportList from "../reports/reportList";
import NotificationList from "../notifications/notificationList";
import AllProjects from "../masterFL/allprojects";
import Users from "../masterFL/users";
import ApexChart from "../projectList/ApexChart";
import AvatarD from "../contents/avatar";
import { ChevronDownIcon } from "lucide-react";
import { MdOutlineVisibility } from "react-icons/md";
import AllUsers from "../masterFL/allusers";

function MasterDashboard() {
  const userData = useCurrentUser();
  const [activePage, setActivePage] = useState("Dashboard");
  const [timeFrame, setTimeFrame] = useState("Daily");

  const renderContent = () => {
    switch (activePage) {
      case "Dashboard":
        return (
          <>
            <div className="DashboardDivMaster">
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  flexDirection: "column",
                  width: "100%",
                  height: "95%",
                }}
              >
                <MDBContainer
                  style={{
                    display: "flex",
                    height: "100%",
                    padding: "0",
                    gap: "10px",
                    flexDirection: "column",
                  }}
                >
                  <div className="dashboardHeader">
                    <h1 className="title-h1">Dashboard</h1>
                  </div>
                  <div className="dashboardSection">
                    <div className="apexChart">
                      <div className="apexChartHeader">
                        <Menu isLazy>
                          <MenuButton
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                            className="tabBtnChart"
                          >
                            {timeFrame}
                          </MenuButton>
                          <MenuList
                            style={{
                              background: "black",
                              border: "none",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              padding: "8% 0",
                              zIndex: "10",
                            }}
                          >
                            <MenuItem
                              className="tabBtnChartItem"
                              onClick={() => setTimeFrame("Daily")}
                            >
                              Daily
                            </MenuItem>
                            <Divider
                              w="85%"
                              justifySelf="center"
                              color="#ffffff50"
                            />
                            <MenuItem
                              className="tabBtnChartItem"
                              onClick={() => setTimeFrame("Monthly")}
                            >
                              Monthly
                            </MenuItem>
                            <Divider
                              w="85%"
                              justifySelf="center"
                              color="#ffffff50"
                            />
                            <MenuItem
                              className="tabBtnChartItem"
                              onClick={() => setTimeFrame("Yearly")}
                            >
                              Yearly
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </div>
                      <ApexChart timeFrame={timeFrame} />
                    </div>
                    <div className="sideTwoSection">
                      <div className="sideTwoSectionOne">
                        <span>
                          <div class="block-container">
                            <div class="btn-front">
                              <BsOpencollective className="icon" />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon"
                                width="48"
                                height="48"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M12.3999 17.4999C11.8999 17.2999 11.2999 17.3999 11.0999 17.8999L9.29989 21.4999C8.99989 21.9999 9.19989 22.5999 9.69989 22.8999C9.79989 22.9999 9.99989 22.9999 10.1999 22.9999C10.5999 22.9999 10.8999 22.7999 11.0999 22.4999L12.8999 18.8999C13.0999 18.2999 12.8999 17.6999 12.3999 17.4999Z"
                                  fill="url(#gradient-full)"
                                />
                                <path
                                  d="M17 17.4999C16.5 17.2999 15.9 17.3999 15.7 17.8999L13.9 21.4999C13.7 21.9999 13.8 22.5999 14.3 22.7999C14.4 22.8999 14.6 22.8999 14.8 22.8999C15.2 22.8999 15.5 22.6999 15.7 22.3999L17.5 18.7999C17.7 18.2999 17.5 17.6999 17 17.4999Z"
                                  fill="url(#gradient-full)"
                                />
                                <path
                                  d="M7.89994 17.4999C7.39994 17.2999 6.79994 17.3999 6.59994 17.8999L4.79994 21.4999C4.59994 21.9999 4.69994 22.5999 5.19994 22.7999C5.29994 22.9999 5.49994 22.9999 5.59994 22.9999C5.99994 22.9999 6.29994 22.7999 6.49994 22.4999L8.29994 18.8999C8.59994 18.2999 8.39994 17.6999 7.89994 17.4999Z"
                                  fill="url(#gradient-full)"
                                />
                                <path
                                  d="M15.2 1C12.4 1 9.9 2.5 8.5 4.8C8 4.7 7.5 4.6 7 4.6C3.7 4.6 1 7.3 1 10.6C1 13.9 3.7 16.6 7 16.6H15.2C19.5 16.6 23 13.1 23 8.8C23 4.5 19.5 1 15.2 1Z"
                                  fill="url(#gradient-full)"
                                />
                              </svg>
                            </div>
                          </div>
                          <span className="stat">
                            <Chart2 />
                          </span>
                        </span>
                      </div>
                      <div className="sideTwoSectionTwo">
                        <div className="top">
                          <div class="block-container">
                            <div class="btn-front">
                              <MdOutlineVisibility className="icon icon2" />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon"
                                width="48"
                                height="48"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M12.3999 17.4999C11.8999 17.2999 11.2999 17.3999 11.0999 17.8999L9.29989 21.4999C8.99989 21.9999 9.19989 22.5999 9.69989 22.8999C9.79989 22.9999 9.99989 22.9999 10.1999 22.9999C10.5999 22.9999 10.8999 22.7999 11.0999 22.4999L12.8999 18.8999C13.0999 18.2999 12.8999 17.6999 12.3999 17.4999Z"
                                  fill="url(#gradient-full)"
                                />
                                <path
                                  d="M17 17.4999C16.5 17.2999 15.9 17.3999 15.7 17.8999L13.9 21.4999C13.7 21.9999 13.8 22.5999 14.3 22.7999C14.4 22.8999 14.6 22.8999 14.8 22.8999C15.2 22.8999 15.5 22.6999 15.7 22.3999L17.5 18.7999C17.7 18.2999 17.5 17.6999 17 17.4999Z"
                                  fill="url(#gradient-full)"
                                />
                                <path
                                  d="M7.89994 17.4999C7.39994 17.2999 6.79994 17.3999 6.59994 17.8999L4.79994 21.4999C4.59994 21.9999 4.69994 22.5999 5.19994 22.7999C5.29994 22.9999 5.49994 22.9999 5.59994 22.9999C5.99994 22.9999 6.29994 22.7999 6.49994 22.4999L8.29994 18.8999C8.59994 18.2999 8.39994 17.6999 7.89994 17.4999Z"
                                  fill="url(#gradient-full)"
                                />
                                <path
                                  d="M15.2 1C12.4 1 9.9 2.5 8.5 4.8C8 4.7 7.5 4.6 7 4.6C3.7 4.6 1 7.3 1 10.6C1 13.9 3.7 16.6 7 16.6H15.2C19.5 16.6 23 13.1 23 8.8C23 4.5 19.5 1 15.2 1Z"
                                  fill="url(#gradient-full)"
                                />
                              </svg>
                            </div>
                          </div>
                          <h2
                            style={{
                              fontFamily: "Quicksand",
                              fontWeight: "500",
                            }}
                          >
                            Visitors
                          </h2>
                        </div>
                        <div className="bottom">
                          <div
                            className="container"
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Chart3 />
                            <Chart2 />
                          </div>
                          <section class="py-5 section-bubble1"><svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#646464"
                            viewBox="0 0 1185 248"
                          >
                            <circle cx="76" cy="121.1" r="20" class="a" />
                            <circle cx="870" cy="201.1" r="11" class="a" />
                            <circle cx="814.5" cy="165.6" r="24.5" class="a" />
                            <path
                              d="M0 0v17.7c22.7 14.8 53 31.9 90.7 51.5 150.8 78 322 116.6 424.8 69.3 102.9-47.4 138-69.3 210.8-69.3s118.3 48.6 219.5 38.3 76.3-59.3 188.7-59.3c18.9 0 35.5 2.6 50.5 6.8V0H0z"
                              class="a"
                            />
                          </svg></section>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </MDBContainer>
              </div>
            </div>
          </>
        );
      case "List":
        return <ShapeSection title={"Projects"} compenent={<ProjectList />} />;
      case "ListProject":
        return (
          <ShapeSection title={"All Projects"} compenent={<AllProjects />} />
        );
      case "ListUsers":
        return <ShapeSection title={"All Users"} compenent={<AllUsers />} />;
      default:
        return null;
    }
  };

  return (
    <div className="all-things-here">
      <Header
        children={activePage}
        user={userData}
        onItemSelected={setActivePage}
      />
      {renderContent()}
      <Users />
    </div>
  );
}

export default MasterDashboard;
