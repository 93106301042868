import React, { useEffect, useState } from "react";
import "./HomePage.css";
import SignUp from "./signUp";
import SignIn from "./signIn";
import FPModal from "./fpModal";
import Loading from "../loading";
import { useNavigate } from "react-router-dom";
import useNavigateUserRole from "./checkUser";

const Based = () => {
  const [isSignUpActive, setIsSignUpActive] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useNavigateUserRole(userId, navigate);

  const toggleForm = () => {
    setIsSignUpActive(!isSignUpActive);
  };

  const toggleOpen = () => setBasicModal(!basicModal);

  useEffect(() => {
    const storedUser =
      localStorage.getItem("user") || sessionStorage.getItem("user");
    if (storedUser) {
      setLoadingPage(true);
      const user = JSON.parse(storedUser);
      setUserId(user.uid); // Set userId to trigger the navigation hook
    } else {
      setLoadingPage(false);
    }
  }, []);

  if (loadingPage) return <Loading />;

  return (
    <div
      className={`container-LR ${isSignUpActive ? "active" : ""}`}
      id="container-LR"
    >
      {isSignUpActive ? <SignUp /> : <SignIn toggleOpen={toggleOpen} />}
      <div className="toggle-container-LR">
        <div className="toggle">
          <div className="toggle-panel toggle-left">
            <h1>Welcome Back!</h1>
            <p>Enter your personal details to use all of the site's features</p>
            <button className="hidden" onClick={toggleForm} id="login">
              Sign In
            </button>
          </div>
          <div className="toggle-panel toggle-right">
            <h1>Hello, Friend!</h1>
            <p>
              Register with your personal details to use all of the site's
              features
            </p>
            <button className="hidden" onClick={toggleForm} id="register">
              Sign Up
            </button>
          </div>
        </div>
      </div>
      <div style={{ zIndex: "10" }}>
        <FPModal basicModal={basicModal} setBasicModal={setBasicModal} />
      </div>
    </div>
  );
};

export default Based;
