import React, { useState, useEffect } from "react";
import { auth, db } from "./firebase";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { MDBBtn, MDBTooltip } from "mdb-react-ui-kit";
import useCurrentUser from "./currentUser/currentuser";

function LeaderRegister() {
  const [hasRequested, setHasRequested] = useState(false);

  const userData = useCurrentUser();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setHasRequested(userData.hasRequested || false);
        }
      }
    };

    fetchUserData();
  }, []);

  const handleProgrammerRequest = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (user && !hasRequested) {
      try {
        await setDoc(doc(db, "leaderRequests", user.uid), {
          email: user.email,
          name: userData.firstName,
          userId: user.uid,
          role: "groupleader",
          status: "pending",
        });

        // Update user's document to prevent future requests
        await setDoc(
          doc(db, "users", user.uid),
          {
            hasRequested: true,
          },
          { merge: true }
        );

        console.log("Programmer request sent:", user);
        setHasRequested(true);
      } catch (error) {
        console.error("Error sending programmer request:", error.message);
      }
    } else if (hasRequested) {
      console.log("Request already made.");
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={`container-LR`} id="container-LR">
        <div className="toggle-container-LR">
          <div className="toggle">
            <div className="toggle-panel toggle-right">
              <h1>Hello, Programmer!</h1>
              <p>
                Register with your mail and user name to send request of been
                leader
              </p>
            </div>
          </div>
        </div>
        <div style={{ zIndex: "10" }}>
          <div className="form-container-LR sign-in">
            <form>
              <h1>Request</h1>
              <input
                value={`${userData.firstName} ${userData.lastName}`}
                disabled
                type="text"
                placeholder="User Name"
              />
              <input
                value={userData.email}
                disabled
                type="email"
                placeholder="Email"
              />
              <MDBTooltip
                tag="p"
                wrapperClass="d-inline-block"
                title="You have already made a request"
              >
                <MDBBtn
                  size="lg"
                  onClick={handleProgrammerRequest}
                  disabled={hasRequested}
                >
                  {hasRequested ? "Request Sended" : "Send Request"}
                </MDBBtn>
              </MDBTooltip>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaderRegister;
