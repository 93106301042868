import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { auth, db } from "./firebase";
import { useAuth } from "./AuthContext"; // Import the useAuth hook from your AuthProvider
import { getDoc, doc } from "firebase/firestore";
import Loading from "./loading"; // Assume you have a loading spinner component

function PrivateRoute({ allowedRoles }) {
  const { user, loading: authLoading } = useAuth(); // Destructure loading
  const [authorized, setAuthorized] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUserRole = async () => {
      setIsLoading(true);
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          const userRole = userDoc.data().role;
          setAuthorized(allowedRoles.includes(userRole));
        } catch (error) {
          console.error("Error checking user role:", error.message);
          setAuthorized(false);
        }
      } else if (!authLoading) {
        setAuthorized(false); // Ensure this only runs when not loading
      }
      setIsLoading(false);
    };

    if (!authLoading) {
      // Only check user role once auth loading is complete
      checkUserRole();
    }
  }, [user, allowedRoles, authLoading]);

  if (authLoading || authorized === null) {
    return <Loading />; // Show loading while waiting for user auth and role check
  }

  return authorized ? <Outlet /> : <Navigate to="/unauthorized" />;
}

export default PrivateRoute;
