import { Alert, AlertIcon } from "@chakra-ui/react";
import { MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, googleProvider, handleSocialSignIn } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import useNavigateUserRole from "./checkUser";
import { BsGoogle, BsFacebook, BsGithub, BsLinkedin } from "react-icons/bs";
import FPModal from "./fpModal";

const SignIn = ({ toggleOpen }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState("");
  useNavigateUserRole(userId, navigate);

  const handleAllgood = async (e) => {
    if (!email || !password) {
      setError("All fields are required.");
      return;
    }

    handleLogin(e);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      setUserId(user.uid);

      if (rememberMe) {
        localStorage.setItem("user", JSON.stringify(user));
      } else {
        sessionStorage.setItem("user", JSON.stringify(user));
      }
    } catch (error) {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  // Check if the user is already logged in
  useEffect(() => {
    const storedUser =
      localStorage.getItem("user") || sessionStorage.getItem("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setUserId(user.uid); // Set userId to trigger the navigation hook
    }
  }, []);

  return (
    <div className="form-container-LR sign-in">
      {showAlert && (
        <Alert
          status="error"
          position="absolute"
          top="20px"
          left="50%"
          transform="translateX(-50%)"
          borderRadius="md"
          width="100%"
        >
          <AlertIcon />
          There was an error processing your request
        </Alert>
      )}
      <form onSubmit={handleAllgood}>
        <h1>Sign In</h1>
        <div className="social-icons">
          <span
            className="icon enabled"
            onClick={() =>
              handleSocialSignIn(googleProvider, navigate, rememberMe)
            }
          >
            <BsGoogle />
          </span>
          <span href="" className="icon disabled">
            <BsFacebook />
          </span>
          <span href="" className="icon disabled">
            <BsGithub />
          </span>
          <span href="" className="icon disabled">
            <BsLinkedin />
          </span>
        </div>
        <span>or use your email password</span>
        <input
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type="email"
          placeholder="Email"
        />
        <input
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type="password"
          placeholder="Password"
        />
        <div className="last-side">
          <div>
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              name="remember"
              id="remember"
            />
            <label htmlFor="remember">Remember me</label>
          </div>
          <div>
            <a href="#" onClick={toggleOpen}>
              Forget Your Password?
            </a>
          </div>
        </div>
        <button
          type="submit"
          disabled={email === "" || password === "" || loading}
        >
          {loading ? (
            <MDBSpinner size="sm" role="status" tag="span" />
          ) : (
            "Sign In"
          )}
        </button>
      </form>
    </div>
  );
};

export default SignIn;
