import { Avatar } from "@chakra-ui/react";
import { MDBCardHeader, MDBTypography } from "mdb-react-ui-kit";
import React from "react";

export default function ProfileHeader({ userData }) {
  return (
    <MDBCardHeader className="profileHeader">
      <Avatar
        className={userData.role === "programmer" || userData.role === "groupleader" ? "profilePicRating" : "profilePic"}
        size="2xl"
        name={`${userData.firstName} ${userData.lastName}`}
      />
      <figure className="text-center profileFigure mb-0">
        <MDBTypography
          tag="div"
          className="display-4 pb-3 mb-3 profileUserName"
        >
          <p>{`${userData.firstName} ${userData.lastName}`}</p>
        </MDBTypography>
        <figcaption className="blockquote-footer mb-0 profileUserMail">
          {userData.role}
        </figcaption>
      </figure>
    </MDBCardHeader>
  );
}
