import React, { useState, useEffect } from "react";
import { auth, db } from "./firebase";
import { setDoc, doc, getDoc, onSnapshot } from "firebase/firestore";
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox,
  MDBCard,
  MDBCardBody,
  MDBPopover,
  MDBPopoverBody,
  MDBTooltip,
} from "mdb-react-ui-kit";
import Header from "./contents/header";
import useCurrentUser from "./currentUser/currentuser";
import { TbRuler } from "react-icons/tb";
import { Button, Stack } from "@chakra-ui/react";

function ProgrammerRegister() {
  const [hasRequested, setHasRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const userData = useCurrentUser();

  useEffect(() => {
    let unsubscribe; // Initialize a variable to store the unsubscribe function

    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);

        // Set up real-time listener for the user's document
        unsubscribe = onSnapshot(
          userDocRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              const userData = docSnapshot.data();
              setHasRequested(userData.hasRequested || false);
              setIsLoading(false);
            }
          },
          (error) => {
            console.error("Error fetching user data: ", error);
          }
        );
      }
    };

    fetchUserData();

    // Return the unsubscribe function to clean up the listener
    return () => {
      if (unsubscribe) {
        unsubscribe(); // Clean up the real-time listener when the component unmounts
      }
    };
  }, []);

  const handleProgrammerRequest = async (e) => {
    if (isLoading) {
      return;
    }
    e.preventDefault();
    const user = auth.currentUser;
    if (user && !hasRequested) {
      try {
        await setDoc(doc(db, "programmerRequests", user.uid), {
          email: user.email,
          name: userData.firstName,
          userId: user.uid,
          role: "programmer",
          status: "pending",
        });

        // Update user's document to prevent future requests
        await setDoc(
          doc(db, "users", user.uid),
          {
            hasRequested: true,
          },
          { merge: true }
        );

        setHasRequested(true);
      } catch (error) {
        console.error("Error sending programmer request:", error.message);
      }
    } else if (hasRequested) {
      console.log("Request already made.");
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={`container-LR`} id="container-LR">
        <div className="toggle-container-LR">
          <div className="toggle">
            <div className="toggle-panel toggle-right">
              <h1>Hello, Client!</h1>
              <p>
                Register with your mail and user name to send request of been
                programmer
              </p>
            </div>
          </div>
        </div>
        <div style={{ zIndex: "10" }}>
          <div className="form-container-LR sign-in">
            <form>
              <h1>Request</h1>
              <input
                value={`${userData.firstName} ${userData.lastName}`}
                disabled
                type="text"
                placeholder="User Name"
              />
              <input
                value={userData.email}
                disabled
                type="email"
                placeholder="Email"
              />
              <MDBTooltip
                tag="p"
                wrapperClass="d-inline-block"
                title="You have already made a request"
              >
                <MDBBtn
                  size="lg"
                  onClick={handleProgrammerRequest}
                  disabled={hasRequested}
                >
                  {isLoading && (
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                  {!isLoading && hasRequested
                    ? "Request Sended"
                    : !isLoading && "Send Request"}
                </MDBBtn>
              </MDBTooltip>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgrammerRegister;
