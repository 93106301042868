import React, { useState, useEffect } from "react";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../firebase";
import { useAuth } from "../AuthContext";
import PNotFound from "./PNotFound";
import NotLoggedIn from "./notLoggedIn";
import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBInput,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
  MDBTypography,
} from "mdb-react-ui-kit";
import ProfileHeader from "./profileHeader";
import "./profiles.css";
import Loading from "../loading";
import {
  Chart2,
  Chart4tip,
  DataProfile1,
  DataProfile2,
} from "../masterFL/charts";
import { Box, Divider } from "@chakra-ui/react";
import Project from "../projectList/project";

function ProfilePage() {
  const { userId } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [basicActive, setBasicActive] = useState("Information");
  const [projects, setProjects] = useState([]);

  // Real-time listener for projects
  useEffect(() => {
    const fetchProjects = () => {
      setLoading(true);
      const projectsQuery = query(
        collection(db, "projects"),
        orderBy("publicationDate", "desc")
      );

      // Real-time listener for changes in the projects collection
      const unsubscribe = onSnapshot(
        projectsQuery,
        (querySnapshot) => {
          const projectData = [];
          querySnapshot.forEach((doc) => {
            if (doc.data().userId === userId) {
              projectData.push({ id: doc.id, ...doc.data() });
            }
          });
          setProjects(projectData);
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching projects:", error);
          setLoading(false);
        }
      );

      return () => unsubscribe(); // Cleanup the listener
    };

    fetchProjects();
  }, [userId]);

  // Real-time listener for profile data
  useEffect(() => {
    if (!user) {
      setLoading(false);
      return;
    }

    const fetchProfile = () => {
      const docRef = doc(db, "users", userId);

      // Real-time listener for changes in the profile document
      const unsubscribe = onSnapshot(
        docRef,
        (docSnap) => {
          if (docSnap.exists()) {
            setProfileData(docSnap.data());
          } else {
            setProfileData(null);
          }
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching profile:", error.message);
          setLoading(false);
        }
      );

      return () => unsubscribe(); // Cleanup the listener
    };

    fetchProfile();
  }, [userId, user, navigate]);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  if (loading) return <Loading />;

  if (!user) {
    return <NotLoggedIn />;
  }

  if (!profileData) {
    return <PNotFound />;
  }

  return (
    <div className="profileDiv">
      <MDBContainer className="profileContainer">
        <MDBCard className="profileCard">
          <ProfileHeader userData={profileData} />
          <MDBCardBody className="profileBody">
            {profileData.role !== "support" ? (
              <section className="section-1">
                <DataProfile1 role={profileData.role} user={profileData} />
                <Box className="notificationsDividerVertical">
                  <Divider orientation="horizontal" borderColor="transparent" />
                </Box>
                <DataProfile2 role={profileData.role} user={profileData} />
              </section>
            ) : (
              <section className="section-1">
                <DataProfile1 role={profileData.role} user={profileData} />
              </section>
            )}
            <section className="section-2">
              <div className="usersSection">
                <MDBTabs pills className="mb-3 tabsMDB profileTabsMDB">
                  <MDBTabsItem>
                    <MDBTabsLink
                      className="tabBtn"
                      onClick={() => handleBasicClick("Information")}
                      active={basicActive === "Information"}
                    >
                      Information
                    </MDBTabsLink>
                  </MDBTabsItem>
                  {profileData.role === "client" ? (
                    <MDBTabsItem>
                      <MDBTabsLink
                        className="tabBtn"
                        onClick={() => handleBasicClick("Projects")}
                        active={basicActive === "Projects"}
                      >
                        Projects
                      </MDBTabsLink>
                    </MDBTabsItem>
                  ) : (
                    <MDBTabsItem>
                      <MDBTabsLink
                        className="tabBtn"
                        onClick={() => handleBasicClick("Review")}
                        active={basicActive === "Review"}
                      >
                        Review
                      </MDBTabsLink>
                    </MDBTabsItem>
                  )}
                </MDBTabs>
                <MDBTabsContent className="tabsMDBContent">
                  <MDBTabsPane
                    className="paneProfile"
                    open={basicActive === "Review"}
                  >
                    review
                  </MDBTabsPane>
                  <MDBTabsPane
                    open={basicActive === "Information"}
                    className="paneProfile"
                  >
                    <MDBContainer className="profilePageContainer">
                      <span className="profilePageSpan-2">
                        <span className="spanInputsProfile">
                          <MDBInput
                            labelStyle={{ color: "#ecdfcc" }}
                            label="First Name"
                            id="formControlDisabled"
                            type="text"
                            disabled
                            value={profileData.firstName}
                          />
                          <MDBInput
                            labelStyle={{ color: "#ecdfcc" }}
                            label="Last Name"
                            id="formControlDisabled"
                            type="text"
                            disabled
                            value={profileData.lastName}
                          />
                        </span>
                        <span className="spanInputsProfile">
                          <MDBInput
                            labelStyle={{ color: "#ecdfcc" }}
                            label="Username"
                            id="formControlDisabled"
                            type="text"
                            disabled
                            value={`${profileData.firstName} ${profileData.lastName}`}
                          />
                          <MDBInput
                            labelStyle={{ color: "#ecdfcc" }}
                            label="Phone number"
                            id="formControlDisabled"
                            type="text"
                            disabled
                            hidden={!profileData.showPhone}
                            value={profileData.number}
                          />
                        </span>
                        <span className="spanInputsProfile">
                          <MDBInput
                            labelStyle={{ color: "#ecdfcc" }}
                            label="E-mail"
                            id="formControlDisabled"
                            type="text"
                            disabled
                            value={
                              profileData.showEmail
                                ? profileData.email
                                : `******`
                            }
                          />
                          <MDBInput
                            labelStyle={{ color: "#ecdfcc" }}
                            label="Birthday"
                            id="formControlDisabled"
                            type="date"
                            hidden={!profileData.showBirthday}
                            disabled
                            value={profileData.birthday}
                          />
                        </span>
                      </span>
                    </MDBContainer>
                  </MDBTabsPane>
                  <MDBTabsPane
                    open={basicActive === "Projects"}
                    className="paneProfile"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        margin: "1% 0",
                      }}
                    >
                      {projects.length !== 0 ? (
                        projects.map((project) => (
                          <Project
                            key={project.id}
                            clientN={`${project.firstName} ${project.lastName}`}
                            dateP={project.publicationDate}
                            stat={project.status}
                            leader={project.leader}
                            puid={project.userId}
                            body={project.body}
                            id={project.id}
                            minAmount={project.minAmount}
                            maxAmount={project.maxAmount}
                          />
                        ))
                      ) : (
                        <div className="NoProjectsDivProfile">
                          <h3>No Projects Available</h3>
                          <p>
                            It seems the user hasn't created any projects yet.
                          </p>
                        </div>
                      )}
                    </div>
                  </MDBTabsPane>
                </MDBTabsContent>
              </div>
            </section>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </div>
  );
}

export default ProfilePage;
