import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBTypography,
} from "mdb-react-ui-kit";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  onSnapshot,
  query,
  limit,
} from "firebase/firestore";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";
import "../notifications/notification.css";
import { db } from "../firebase";
import { Box, Divider } from "@chakra-ui/react";
import ReportI from "./report";

export default function ReportListModel({ optSmModal, toggleOpen }) {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const fetchReports = () => {
      const reportsCollection = collection(db, "reports");

      // Real-time listener for reports collection
      const unsubscribeReports = onSnapshot(
        reportsCollection,
        async (reportSnapshot) => {
          const reportsData = await Promise.all(
            reportSnapshot.docs.map(async (reportDoc) => {
              const reportData = reportDoc.data();

              // Fetch the publication related to the report
              const publicationDoc = await getDoc(
                doc(db, "projects", reportData.publicationId)
              );
              const publicationData = publicationDoc.exists()
                ? publicationDoc.data()
                : {};

              // Fetch the user who published the reported content
              const publicationUserDoc = await getDoc(
                doc(db, "users", reportData.publicationUserId)
              );

              // Fetch the user who reported the content
              const reportedByUserDoc = await getDoc(
                doc(db, "users", reportData.reportedById)
              );

              return {
                ...reportData,
                publicationUserName: publicationUserDoc.exists()
                  ? `${publicationUserDoc.data().firstName} ${
                      publicationUserDoc.data().lastName
                    }`
                  : "Unknown User",
                reportedByUserName: reportedByUserDoc.exists()
                  ? `${reportedByUserDoc.data().firstName} ${
                      reportedByUserDoc.data().lastName
                    }`
                  : "Unknown User",
                publication: publicationData,
              };
            })
          );

          setReports(reportsData);
        }
      );

      return unsubscribeReports; // Cleanup to stop listening when component unmounts
    };

    const unsubscribe = fetchReports();

    return () => {
      unsubscribe(); // Unsubscribe from the listener on component unmount
    };
  }, []);
  return (
    <MDBModal open={optSmModal} tabIndex="-1">
      <MDBModalDialog scrollable>
        <MDBModalContent className="notificationsContentModal">
          <MDBModalHeader className="notificationsContentHeaderModal">
            <MDBBtn
              color="light"
              className="btn-close"
              onClick={toggleOpen}
            ></MDBBtn>
          </MDBModalHeader>
          <Box className="notificationsDivider">
            <Divider orientation="horizontal" borderColor="transparent" />
          </Box>
          <MDBModalBody style={{ overflow: "auto", width: "100%" }}>
            <div className="usersSection">
              <MDBListGroup
                className="listGroup"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "2%",
                  border: "none",
                }}
              >
                {reports.map((report, index) => (
                  <MDBListGroupItem
                    key={index}
                    className="d-flex justify-content-between align-items-center listGroupItem"
                  >
                    <ReportI
                      userName={report.reportedByUserName}
                      userReportedName={report.publicationUserName}
                      userReportedId={report.reportedById}
                      publicationId={report.publicationId}
                      date={report.publication.dateP}
                      body={report.publication.body}
                      userId={report.publication.userId}
                    />
                  </MDBListGroupItem>
                ))}
              </MDBListGroup>
            </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
