import React, { useEffect, useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
} from "mdb-react-ui-kit";
import SetProjectTable from "./setprojecttable";

function CardView({ displayFilter, sortFilter, groupFilter }) {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const unsubscribe = SetProjectTable({
      displayFilter: displayFilter,
      sortFilter: sortFilter,
      groupFilter: groupFilter,
      onUpdate: (projects) => {
        setProjects(projects);
      },
    });

    return () => unsubscribe(); // Cleanup on component unmount
  }, []);
  return (
    <div className="d-flex flex-wrap">
      {projects.map((project) => (
        <MDBCard style={{ width: "18rem", margin: "10px" }} key={project.id}>
          <MDBCardBody>
            <MDBCardTitle>
              {project.firstName} {project.lastName}
            </MDBCardTitle>
            <MDBCardText>
              Status: {project.status}
              <br />
              Work: {project.work}
              <br />
              Leader: {project.leader || "No leader"}
            </MDBCardText>
          </MDBCardBody>
          <MDBCardFooter>
            <small className="text-muted">
              Date:{" "}
              {new Date(project.publicationDate).toLocaleDateString("en-US", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}
            </small>
          </MDBCardFooter>
        </MDBCard>
      ))}
    </div>
  );
}

export default CardView;
