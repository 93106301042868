import React from "react";
import Sidebar from "./sidebar";

function Header({children, user, onItemSelected }) {
  return (
    <>
      <Sidebar children={children} user={user} onItemSelected={onItemSelected} />
    </>
  );
}

export default Header;
