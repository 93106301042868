import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import SetProjectTable from "./setprojecttable";

const localizer = momentLocalizer(moment);

function CalendarView({ displayFilter, sortFilter, groupFilter }) {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const unsubscribe = SetProjectTable({
      displayFilter: displayFilter,
      sortFilter: sortFilter,
      groupFilter: groupFilter,
      onUpdate: (projects) => {
        setProjects(projects);
      },
    });

    return () => unsubscribe(); // Cleanup on component unmount
  }, []);
  const events = projects.map((project) => ({
    title: `${project.firstName} ${project.lastName}`,
    start: new Date(project.publicationDate),
    end: new Date(project.publicationDate),
    allDay: true,
  }));

  return (
    <div style={{ height: 500 }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100%" }}
      />
    </div>
  );
}

export default CalendarView;
