import React, { useEffect, useState } from "react";
import useCurrentUser from "../currentUser/currentuser";
import Loading from "../loading";

export default function NotificationSended({ to, projectId, checked }) {
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const UserC = useCurrentUser();

  useEffect(() => {
    if (UserC) {
      setIsUserLoaded(true);
    }
  }, [UserC]);

  if (!isUserLoaded) {
    return <Loading />;
  }

  return (
    <div
      className="d-flex align-items-center"
      style={{
        width: "100%",
        padding: "5% 5%",
        background: "#0f0f0f75",
        borderRadius: "20px",
      }}
    >
      <div
        className="ms-3"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {checked && UserC.uid === to ? (
          <span
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              className="fw-bold mb-1"
              style={{ color: "red", textAlign: "center" }}
            >
              Project Removed: {projectId}
            </p>
            <p
              className="text-muted mb-0"
              style={{ textAlign: "center", fontSize: "12px" }}
            >
              Your project has been deleted by the master due
              to a violation of our guidelines. Please review the terms and
              ensure compliance in future submissions. If you have any
              questions, feel free to contact support.
            </p>
          </span>
        ) : checked === false && (
          <span
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              className="fw-bold mb-1"
              style={{ color: "green", textAlign: "center" }}
            >
              Report Cancelled: {projectId}
            </p>
            <p
              className="text-muted mb-0"
              style={{ textAlign: "center", fontSize: "12px" }}
            >
              The report for your project was reviewed and
              found to be incorrect. The project has not been deleted. Thank you
              for your understanding. If you have any questions or need further
              assistance, please contact support.
            </p>
          </span>
        )}
      </div>
    </div>
  );
}
