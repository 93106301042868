import React, { useEffect, useState } from "react";
import { MDBBtn } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import useCurrentUser from "./currentUser/currentuser"; // Fetch the current user's role
import Loading from "./loading"; // Loading spinner

function Unauthorized() {
  const navigate = useNavigate();
  const { user, loading: authLoading } = useAuth(); // Get auth loading state
  const { role, loading: userLoading } = useCurrentUser(); // Custom hook to fetch user data
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!authLoading && !userLoading) {
      if (user && role) {
        navigate(`/${role}`); // Navigate when both user and role are ready
      } else {
        setLoading(false); // Stop loading once check is complete
      }
    }
  }, [authLoading, userLoading, user, role, navigate]);

  const handleGoBack = () => {
    navigate("/");
  };

  if (loading || authLoading || userLoading) {
    return <Loading />; // Show loading while waiting for auth or role data
  }

  return (
    <div className="UnauthDiv">
      <h1>Unauthorized</h1>
      <p>Sorry, you do not have permission to view this page.</p>
      <MDBBtn color="white" outline onClick={handleGoBack}>Go Back</MDBBtn>
    </div>
  );
}

export default Unauthorized;
