import React from "react";
import "./typing.css";
import "./chatDesign.css";
import { AbsoluteCenter } from "@chakra-ui/react";

export default function Typing({ isTyping }) {
  return (
    <span className={`typing friend last ${isTyping ? "active" : ""}`}>
      <AbsoluteCenter>
        <div className="typing__dot"></div>
        <div className="typing__dot"></div>
        <div className="typing__dot"></div>
      </AbsoluteCenter>
    </span>
  );
}
