import React from "react";
import { useNavigate } from "react-router-dom";
import { auth, updateUserConnection } from "./firebase";
import { signOut } from "firebase/auth";
import { LogOutIcon } from "lucide-react";
import { IoLogOut } from "react-icons/io5";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";

function Logout() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      // Ensure the user state is updated before navigating away
      const user = auth.currentUser;
      if (user) {
        await updateUserConnection(user.uid, "disconnect"); // Update connection status
      }

      // Perform the sign out
      await signOut(auth);

      // Clear local storage
      localStorage.removeItem("user");
      sessionStorage.removeItem("user");

      // Navigate to the home page
      navigate("/LRU");
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  // return (
  //   <IoLogOut
  //     color="#ecdfcc"
  //     onClick={handleLogout}
  //     size="18px"
  //     cursor="pointer"
  //   />
  // );
  return (
    <MDBBtn
      floating
      onClick={handleLogout}
      color="danger"
      children={<IoLogOut color="#ecdfcc" size="18px" cursor="pointer" />}
    ></MDBBtn>
  );
}

export default Logout;
