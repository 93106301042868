import React, { useEffect, useRef, useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBSpinner,
  MDBTextArea,
  MDBValidation,
  MDBValidationItem,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import { Box, Divider, Textarea } from "@chakra-ui/react";
import { db } from "../firebase";
import {
  addDoc,
  getDocs,
  collection,
  orderBy,
  query,
  updateDoc,
  doc,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import useCurrentUser from "../currentUser/currentuser";
import Project from "./project";
import Loading from "../loading";

function ProjectList() {
  const [body, setBody] = useState("");
  const [subject, setSubject] = useState("");
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");

  const userData = useCurrentUser();

  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // for tracking the current page
  const [projectsPerPage] = useState(7); // number of projects per page
  const [pageWindowSize] = useState(5); // Number of visible page links

  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);

  const handleOpen = () => {
    setOpen(!open);
    console.log(open);
  };

  useEffect(() => {
    if (open) {
      inputRef.current?.focus();
    }
  }, [open]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const projectsQuery = query(
          collection(db, "projects"),
          orderBy("publicationDate", "desc")
        );

        const unsubscribe = onSnapshot(projectsQuery, (querySnapshot) => {
          const projectData = [];
          querySnapshot.forEach((doc) => {
            projectData.push({ id: doc.id, ...doc.data() });
          });
          setProjects(projectData);
          setLoading(false);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching projects:", error);
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const totalPages = Math.ceil(projects.length / projectsPerPage);
  // Calculate the range of pages to display in pagination
  const startPage = Math.max(1, currentPage - Math.floor(pageWindowSize / 2));
  const endPage = Math.min(totalPages, startPage + pageWindowSize - 1);
  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePublish = async () => {
    try {
      const userId = userData.uid;
      const firstName = userData.firstName;
      const lastName = userData.lastName;
      const publicationDate = new Date();

      const docRef = await addDoc(collection(db, "projects"), {
        userId: userId,
        firstName: firstName,
        lastName: lastName,
        publicationDate: publicationDate,
        status: "waiting",
        leader: "",
        leaderId: "",
        work: "still",
        subject: subject,
        body: body,
        minAmount: minAmount,
        maxAmount: maxAmount,
        start: false,
      });

      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const currentMProjects = userDocSnap.data().MProjects || 0;
        const newMProjects = currentMProjects + 1;

        await updateDoc(userDocRef, {
          MProjects: newMProjects,
        });

        await addDoc(collection(db, "notifications"), {
          projectId: docRef.id,
          userId: userId,
          to: "",
        });

        // Clear form fields
        setBody("");
        setSubject("");
        setMinAmount("");
        setMaxAmount("");

        // Close modal after publishing
        setOpen(false);
      } else {
        console.error("User document does not exist!");
      }
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  return (
    <MDBContainer className="projectlistusers-container">
      {loading || projects.length === 0 ? (
        <Loading />
      ) : (
        <>
          {userData.role === "client" && (
            <MDBContainer className="projectlistusers-create-container">
              <MDBBtn color="white" outline onClick={() => handleOpen()}>
                Create a New Project
              </MDBBtn>
            </MDBContainer>
          )}
          {currentProjects.length !== 0 &&
            currentProjects.map((project) => (
              <Project
                key={project.id}
                clientN={`${project.firstName} ${project.lastName}`}
                dateP={project.publicationDate}
                stat={project.status}
                leader={project.leader}
                puid={project.userId}
                body={project.body}
                subject={project.subject}
                id={project.id}
                minAmount={project.minAmount}
                maxAmount={project.maxAmount}
              />
            ))}

          <nav aria-label="..." className="projectlistusers-pagination-nav">
            <MDBPagination
              size="md"
              className="mb-0 projectlistusers-pagination"
            >
              <MDBPaginationItem
                disabled={currentPage <= 1}
                className="projectlistusers-previous-item"
              >
                <MDBPaginationLink
                  href="#"
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </MDBPaginationLink>
              </MDBPaginationItem>
              {pageNumbers.map((number) => (
                <MDBPaginationItem
                  key={number}
                  active={currentPage === number}
                  className="projectlistusers-number-item"
                >
                  <MDBPaginationLink href="#" onClick={() => paginate(number)}>
                    {number}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}
              <MDBPaginationItem
                className="projectlistusers-next-item"
                disabled={currentPage >= pageNumbers.length}
              >
                <MDBPaginationLink
                  href="#"
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination>
          </nav>

          {projects.length === 0 && (
            <div className="projectlistusers-no-projects">
              <h3>No Projects Available</h3>
              <p>It seems there are no projects to display at the moment.</p>
              {userData.role === "client" && (
                <MDBBtn color="white" outline onClick={() => handleOpen()}>
                  Create a New Project
                </MDBBtn>
              )}
            </div>
          )}
        </>
      )}
      <>
        <MDBModal open={open} tabIndex="-1">
          <MDBModalDialog size="lg">
            <MDBModalContent className="notificationsContentModal">
              <MDBModalHeader className="notificationsContentHeaderModal">
                <MDBModalTitle>New Project</MDBModalTitle>
              </MDBModalHeader>
              <Box className="notificationsDivider">
                <Divider orientation="horizontal" borderColor="transparent" />
              </Box>
              <MDBModalBody className="projectnewmodal-body">
                <MDBValidation className="row g-3" isValidated>
                  <MDBValidationItem
                    className="mb-3 pb-1 projectnewmodal-textarea-item"
                    feedback="Please enter a message in the subject."
                    invalid
                  >
                    <MDBInput
                      label="Subject"
                      size="sm"
                      maxLength={50}
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                      className="projectnewmodal-subject"
                      value={subject}
                      id="validationInput"
                      placeholder="Required subject"
                    ></MDBInput>
                  </MDBValidationItem>
                  <MDBValidationItem
                    className="mb-3 pb-1 projectnewmodal-textarea-item"
                    feedback="Please enter a message in the textarea."
                    invalid
                  >
                    <MDBTextArea
                      label="Textarea"
                      size="sm"
                      onChange={(e) => setBody(e.target.value)}
                      className="projectnewmodal-textarea"
                      value={body}
                      id="validationTextarea"
                      placeholder="Required example textarea"
                    />
                  </MDBValidationItem>
                  <MDBValidationItem
                    className="col-md-6 projectnewmodal-input-item"
                    feedback="The minimum amount should be lower than the maximum amount."
                    invalid
                  >
                    <MDBInput
                      type="number"
                      color="white"
                      size="sm"
                      autoComplete="off"
                      value={minAmount}
                      name="Min Amount"
                      id="validationCustom03"
                      max={maxAmount}
                      onChange={(e) => setMinAmount(e.target.value)}
                      label="Min Amount"
                    />
                  </MDBValidationItem>
                  <MDBValidationItem
                    className="col-md-6 projectnewmodal-input-item"
                    feedback="The maximum amount should be higher than the minimum amount."
                    invalid
                  >
                    <MDBInput
                      type="number"
                      color="white"
                      size="sm"
                      value={maxAmount}
                      autoComplete="off"
                      name="Max Amount"
                      id="validationCustom03"
                      min={minAmount}
                      onChange={(e) => setMaxAmount(e.target.value)}
                      label="Max Amount"
                    />
                  </MDBValidationItem>
                </MDBValidation>
              </MDBModalBody>
              <Box className="notificationsDivider">
                <Divider orientation="horizontal" borderColor="transparent" />
              </Box>
              <MDBModalFooter className="projectnewmodal-footer">
                <MDBBtn
                  disabled={
                    body !== "" &&
                    minAmount !== "" &&
                    maxAmount !== "" &&
                    minAmount < maxAmount
                      ? false
                      : true
                  }
                  className="me-1"
                  color={
                    body !== "" &&
                    minAmount !== "" &&
                    maxAmount !== "" &&
                    minAmount < maxAmount
                      ? "success"
                      : "warning"
                  }
                  type="button"
                  onClick={handlePublish}
                >
                  Publish
                </MDBBtn>
                <MDBBtn
                  type="button"
                  color="danger"
                  className="me-1"
                  outline
                  onClick={() => handleOpen()}
                >
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </>
    </MDBContainer>
  );
}

export default ProjectList;
