import React, { useState } from "react";
import { auth } from "./firebase"; // Make sure to import your Firebase auth instance
import { sendPasswordResetEmail } from "firebase/auth";
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBInput,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent! Check your inbox.");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <MDBContainer
      fluid
      className="p-3 my-5"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MDBRow className="justify-content-center align-items-center m-5">
        <MDBCard>
          <MDBCardBody className="px-4">
            <MDBCol col="4">
              <h4>Reset Your Password</h4>
              {message && <p className="text-success">{message}</p>}
              {error && <p className="text-danger">{error}</p>}
              <MDBInput
                wrapperClass="mb-4"
                label="Email address"
                id="emailInput"
                type="email"
                size="lg"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="text-center text-md-start mt-4 pt-2">
                <MDBBtn
                  className="mb-4 w-100"
                  size="lg"
                  onClick={handlePasswordReset}
                >
                  Send Reset Email
                </MDBBtn>
              </div>
            </MDBCol>
          </MDBCardBody>
        </MDBCard>
      </MDBRow>
    </MDBContainer>
  );
}

export default ForgotPassword;
