import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBTypography,
} from "mdb-react-ui-kit";
import ReportI from "./report";
import { db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  query,
} from "firebase/firestore";
import { BiSolidReport } from "react-icons/bi";
import { Box, Divider } from "@chakra-ui/react";
import ReportListModel from "./reportListModel";

export default function ReportList() {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const fetchReports = () => {
      let reportsCollection = collection(db, "reports");
      reportsCollection = query(reportsCollection, limit(4));

      // Real-time listener for reports collection
      const unsubscribeReports = onSnapshot(
        reportsCollection,
        async (reportSnapshot) => {
          const reportsData = await Promise.all(
            reportSnapshot.docs.map(async (reportDoc) => {
              const reportData = reportDoc.data();

              // Fetch the publication related to the report
              const publicationDoc = await getDoc(
                doc(db, "projects", reportData.publicationId)
              );
              const publicationData = publicationDoc.exists()
                ? publicationDoc.data()
                : {};

              // Fetch the user who published the reported content
              const publicationUserDoc = await getDoc(
                doc(db, "users", reportData.publicationUserId)
              );

              // Fetch the user who reported the content
              const reportedByUserDoc = await getDoc(
                doc(db, "users", reportData.reportedById)
              );

              return {
                ...reportData,
                publicationUserName: publicationUserDoc.exists()
                  ? `${publicationUserDoc.data().firstName} ${
                      publicationUserDoc.data().lastName
                    }`
                  : "Unknown User",
                reportedByUserName: reportedByUserDoc.exists()
                  ? `${reportedByUserDoc.data().firstName} ${
                      reportedByUserDoc.data().lastName
                    }`
                  : "Unknown User",
                publication: publicationData,
              };
            })
          );

          setReports(reportsData);
        }
      );

      return unsubscribeReports; // Cleanup to stop listening when component unmounts
    };

    const unsubscribe = fetchReports();

    return () => {
      unsubscribe(); // Unsubscribe from the listener on component unmount
    };
  }, []);

  const [optSmModal, setOptSmModal] = useState(false);

  const toggleOpen = () => setOptSmModal(!optSmModal);

  return (
    <MDBContainer className="notificationsContainer">
      <div className="notificationsHeader">
        <div className="notificationsHeaderOne">
          <BiSolidReport className="notificationIcon" />
          <MDBTypography variant="h2" className="notificationTitle">
            Reports
          </MDBTypography>
        </div>
        <span className="notificationViewAll" onClick={toggleOpen}>
          View All
        </span>
      </div>

      <Box className="notificationsDivider">
        <Divider orientation="horizontal" borderColor="transparent" />
      </Box>
      <div className="usersSection">
        <MDBListGroup
          className="listGroup"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "2%",
            border: "none",
          }}
        >
          {reports.map((report, index) => (
            <MDBListGroupItem
              key={index}
              className="d-flex justify-content-between align-items-center listGroupItem"
            >
              <ReportI
                userName={report.reportedByUserName}
                userReportedName={report.publicationUserName}
                userReportedId={report.reportedById}
                publicationId={report.publicationId}
                date={report.publication.dateP}
                body={report.publication.body}
                userId={report.publication.userId}
              />
            </MDBListGroupItem>
          ))}
        </MDBListGroup>
      </div>
      <Box className="notificationsDivider">
        <Divider orientation="horizontal" borderColor="transparent" />
      </Box>

      <div className="notificationsFooter"></div>
      <ReportListModel optSmModal={optSmModal} toggleOpen={toggleOpen} />
    </MDBContainer>
  );
}
