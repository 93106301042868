import { MDBBtn } from "mdb-react-ui-kit";
import React from "react";
import { useNavigate } from "react-router-dom";

function NotLoggedIn() {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/");
  };
  return (
    <div className="NLIDiv">
      <h1>You are not logged in</h1>
      <MDBBtn color="white" outline onClick={handleGoBack}>
        Go To Loging Page
      </MDBBtn>
    </div>
  );
}

export default NotLoggedIn;
