import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const useNavigateUserRole = (userId, navigate) => {

  useEffect(() => {
    const navigateToUserRole = async () => {
      try {
        const docRef = doc(db, "users", userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          const userRole = userData.role || "client";

          switch (userRole) {
            case "master":
              navigate("/master");
              break;
            case "programmer":
              navigate("/master");
              break;
            case "groupleader":
              navigate("/master");
              break;
            case "support":
              navigate("/master");
              break;
            case "client":
              navigate("/client");
              break;
            default:
              navigate("/notExist");
              break;
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error navigating to user role:", error.message);
      }
    };

    if (userId) {
      navigateToUserRole();
    }
  }, [userId, navigate]);
};

export default useNavigateUserRole;
