import React, { useEffect, useState } from "react";
import {
  MDBBadge,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import SetProjectTable from "./setprojecttable";

export default function Projects({ displayFilter, sortFilter, groupFilter }) {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const unsubscribe = SetProjectTable({
      displayFilter: displayFilter,
      sortFilter: sortFilter,
      groupFilter: groupFilter,
      onUpdate: (projects) => {
        setProjects(projects);
      },
    });

    return () => unsubscribe(); // Cleanup on component unmount
  }, []);

  const GetProject = ({
    key,
    UserName,
    Status,
    Working,
    DateP,
    Leader,
    isActive,
  }) => {
    return (
      <tr key={key} className={isActive ? "table-active" : "table-shut-down"}>
        <td>
          <div className="d-flex align-items-center justify-content-center">
            <div>
              <p className="fw-bold mb-1">{UserName}</p>
            </div>
          </div>
        </td>
        <td>
          <MDBBtn color={Status === "waiting" ? "warning" : "success"}>
            {Status}
          </MDBBtn>
        </td>
        <td>
          <MDBBtn
            color={
              Working === "still"
                ? "danger"
                : Working === "on it"
                ? "warning"
                : Working === "done"
                ? "success"
                : "dark"
            }
          >
            {Working}
          </MDBBtn>
        </td>
        <td>
          {new Date(
            DateP.seconds * 1000 + DateP.nanoseconds / 1000000
          ).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          })}
        </td>
        <td>
          <MDBBtn color="info" size="sm">
            {Leader === "" ? "-" : Leader}
          </MDBBtn>
        </td>
      </tr>
    );
  };

  return (
    <MDBTable borderless style={{ color: "white" }} align="middle">
      <MDBTableHead className="table-shut-down">
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Status</th>
          <th scope="col">Working</th>
          <th scope="col">Date</th>
          <th scope="col">Leader</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {projects.map((project, index) => (
          <GetProject
            key={project.id}
            UserName={`${project.firstName} ${project.lastName}`}
            DateP={project.publicationDate}
            Status={project.status}
            Leader={project.leader}
            Working={project.work}
            isActive={index % 2 === 0} // Example condition: every second row is active
          />
        ))}
      </MDBTableBody>
    </MDBTable>
  );
}
