import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { getAllUsers } from "../firebase";
import Loading from "../loading";
import "./allusers.css";
import { Avatar } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function AllUsers() {
  const [allUsers, setAllUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await getAllUsers();
      setAllUsers(users);
      setUsersLoading(false); // Set loading to false after fetching
    };

    fetchUsers();
  }, []);

  if (usersLoading) {
    return <Loading />;
  }

  return (
    <div className="projectSection">
      <MDBContainer
        style={{
          width: "100%",
          height: "max-content",
          display: "flex",
          flexDirection: "column",
          padding: "25px",
          gap: "20px",
        }}
      >
        <MDBRow>
          {allUsers.length !== 0 ? (
            allUsers.map((user) => (
              <MDBCol key={user.id} xl={4} lg={6} className="mb-4">
                <MDBCard
                  onClick={() => {
                    navigate(`/profile/${user.id}`);
                  }}
                  className="profileCardAllUser"
                >
                  <MDBCardBody className="profileBodyAllUser">
                    <div className="d-flex align-items-center">
                      <Avatar
                        className="avatarAllUser"
                        name={`${user.firstName} ${user.lastName}`}
                      />
                      <div className="ms-3">
                        <p className="fw-bold mb-1">
                          {user.firstName} {user.lastName}
                        </p>
                        <p className="text-muted mb-0">{user.email}</p>
                      </div>
                    </div>
                    <MDBBtn color="white" outline size="sm">{user.role}</MDBBtn>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            ))
          ) : (
            <p>No users found</p>
          )}
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
