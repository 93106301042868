import {
  Box,
  Divider,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { MDBContainer } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { getVisitorCountA, updateVisitorCountA } from "../firebase";
import { GoProject, GoProjectSymlink } from "react-icons/go";
import { VscGithubProject } from "react-icons/vsc";
import { MdOutlineReviews } from "react-icons/md";

export default function Chart1() {
  return (
    <MDBContainer className="chartCard bottom">
      <StatGroup>
        <Stat
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "1.5rem",
          }}
        >
          <StatLabel>Sent</StatLabel>
          <StatNumber>345,670</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            23.36%
          </StatHelpText>
        </Stat>

        <Stat
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "1.5rem",
          }}
        >
          <StatLabel>Clicked</StatLabel>
          <StatNumber>45</StatNumber>
          <StatHelpText>
            <StatArrow type="decrease" />
            9.05%
          </StatHelpText>
        </Stat>
      </StatGroup>
    </MDBContainer>
  );
}

export function Chart2() {
  return (
    <MDBContainer>
      <Stat
        className="statChart2"
        style={{
          display: "flex",
          justifyContent: "center",
          background: "none",
          boxShadow: "none",
        }}
      >
        <StatLabel>Collected Fees</StatLabel>
        <StatNumber>£0.00</StatNumber>
        <StatHelpText>Feb 12 - Feb 28</StatHelpText>
      </Stat>
    </MDBContainer>
  );
}

export function Chart4tip({ userOfProfile }) {
  const display2Client = () => (
    <MDBContainer>
      <Stat
        className="statChart2 statChart2Pf"
        style={{
          display: "flex",
          justifyContent: "center",
          background: "none",
          boxShadow: "none",
        }}
      >
        <StatLabel>Your balance</StatLabel>
        <StatNumber>$0.00</StatNumber>
        <StatHelpText>Feb 12 - Feb 28</StatHelpText>
      </Stat>
    </MDBContainer>
  );

  const display2Programmer = () => (
    <>
      <MDBContainer>
        <Stat
          className="statChart2 statChart2Pf"
          style={{
            display: "flex",
            justifyContent: "center",
            background: "none",
            boxShadow: "none",
          }}
        >
          <StatLabel>Total wining</StatLabel>
          <StatNumber>£0.00</StatNumber>
          <StatHelpText>Feb 12 - Feb 28</StatHelpText>
        </Stat>
      </MDBContainer>
      <Box className="notificationsDividerVertical">
        <Divider orientation="vertical" borderColor="transparent" />
      </Box>
      <MDBContainer>
        <Stat
          className="statChart2 statChart2Pf"
          style={{
            display: "flex",
            justifyContent: "center",
            background: "none",
            boxShadow: "none",
          }}
        >
          <StatLabel>Your balance</StatLabel>
          <StatNumber>£0.00</StatNumber>
          <StatHelpText>Feb 12 - Feb 28</StatHelpText>
        </Stat>
      </MDBContainer>
    </>
  );

  const display2Leader = () => (
    <>
      <MDBContainer>
        <Stat
          className="statChart2 statChart2Pf"
          style={{
            display: "flex",
            justifyContent: "center",
            background: "none",
            boxShadow: "none",
          }}
        >
          <StatLabel>Total wining</StatLabel>
          <StatNumber>£0.00</StatNumber>
          <StatHelpText>Feb 12 - Feb 28</StatHelpText>
        </Stat>
      </MDBContainer>
      <Box className="notificationsDividerVertical">
        <Divider orientation="vertical" borderColor="transparent" />
      </Box>
      <MDBContainer>
        <Stat
          className="statChart2 statChart2Pf"
          style={{
            display: "flex",
            justifyContent: "center",
            background: "none",
            boxShadow: "none",
          }}
        >
          <StatLabel>Your balance</StatLabel>
          <StatNumber>£0.00</StatNumber>
          <StatHelpText>Feb 12 - Feb 28</StatHelpText>
        </Stat>
      </MDBContainer>
    </>
  );

  const display2Master = () => (
    <>
      <MDBContainer>
        <Stat
          className="statChart2 statChart2Pf"
          style={{
            display: "flex",
            justifyContent: "center",
            background: "none",
            boxShadow: "none",
          }}
        >
          <StatLabel>Total wining</StatLabel>
          <StatNumber>£0.00</StatNumber>
          <StatHelpText>Feb 12 - Feb 28</StatHelpText>
        </Stat>
      </MDBContainer>
      <Box className="notificationsDividerVertical">
        <Divider orientation="vertical" borderColor="transparent" />
      </Box>
      <MDBContainer>
        <Stat
          className="statChart2 statChart2Pf"
          style={{
            display: "flex",
            justifyContent: "center",
            background: "none",
            boxShadow: "none",
          }}
        >
          <StatLabel>Your balance</StatLabel>
          <StatNumber>£0.00</StatNumber>
          <StatHelpText>Feb 12 - Feb 28</StatHelpText>
        </Stat>
      </MDBContainer>
    </>
  );

  const useDisplayed = () => {
    switch (userOfProfile.role) {
      case "client":
        return display2Client();
      case "programmer":
        return display2Programmer();
      case "groupleader":
        return display2Leader();
      case "master":
        return display2Master();
      default:
        return <MDBContainer>No data available</MDBContainer>; // Fallback in case of no matching role
    }
  };

  const currentDisplay = useDisplayed();

  return (
    <>
      {currentDisplay}
      <Box className="notificationsDividerVertical">
        <Divider orientation="vertical" borderColor="transparent" />
      </Box>
    </>
  );
}

const renderContent = (role, user) => {
  const getStatLabel = () => {
    switch (role) {
      case "programmer":
        return "Projects Integrated";
      case "client":
        return "Projects Made";
      case "groupleader":
      default:
        return "Projects Completed";
    }
  };

  const getStatNumber = () => {
    switch (role) {
      case "programmer":
        return user.IProjects;
      case "client":
        return user.MProjects;
      case "groupleader":
      default:
        return user.CProjects;
    }
  };

  const getIcon = () => {
    switch (role) {
      case "programmer":
        return <GoProjectSymlink className="icon iconPf" />;
      case "client":
        return <VscGithubProject className="icon iconPf" />;
      case "groupleader":
      default:
        return <GoProject className="icon iconPf" />;
    }
  };

  if (role === "support") {
    return <div>Support Section</div>;
  } else {
    return (
      <div className="sideTwoSectionOne">
        <span>
          <div className="block-container">
            <div className="btn-front">
              {getIcon()}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.3999 17.4999C11.8999 17.2999 11.2999 17.3999 11.0999 17.8999L9.29989 21.4999C8.99989 21.9999 9.19989 22.5999 9.69989 22.8999C9.79989 22.9999 9.99989 22.9999 10.1999 22.9999C10.5999 22.9999 10.8999 22.7999 11.0999 22.4999L12.8999 18.8999C13.0999 18.2999 12.8999 17.6999 12.3999 17.4999Z"
                  fill="url(#gradient-full)"
                />
                <path
                  d="M17 17.4999C16.5 17.2999 15.9 17.3999 15.7 17.8999L13.9 21.4999C13.7 21.9999 13.8 22.5999 14.3 22.7999C14.4 22.8999 14.6 22.8999 14.8 22.8999C15.2 22.8999 15.5 22.6999 15.7 22.3999L17.5 18.7999C17.7 18.2999 17.5 17.6999 17 17.4999Z"
                  fill="url(#gradient-full)"
                />
                <path
                  d="M7.89994 17.4999C7.39994 17.2999 6.79994 17.3999 6.59994 17.8999L4.79994 21.4999C4.59994 21.9999 4.69994 22.5999 5.19994 22.7999C5.29994 22.9999 5.49994 22.9999 5.59994 22.9999C5.99994 22.9999 6.29994 22.7999 6.49994 22.4999L8.29994 18.8999C8.59994 18.2999 8.39994 17.6999 7.89994 17.4999Z"
                  fill="url(#gradient-full)"
                />
                <path
                  d="M15.2 1C12.4 1 9.9 2.5 8.5 4.8C8 4.7 7.5 4.6 7 4.6C3.7 4.6 1 7.3 1 10.6C1 13.9 3.7 16.6 7 16.6H15.2C19.5 16.6 23 13.1 23 8.8C23 4.5 19.5 1 15.2 1Z"
                  fill="url(#gradient-full)"
                />
              </svg>
            </div>
          </div>
          <span className="stat">
            <MDBContainer>
              <Stat
                className="statChart2 statChart2Pf"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  background: "none",
                  boxShadow: "none",
                }}
              >
                <StatLabel>{getStatLabel()}</StatLabel>
                <StatNumber>{getStatNumber()}</StatNumber>
              </Stat>
            </MDBContainer>
          </span>
        </span>
      </div>
    );
  }
};
const renderContent2 = (role, user) => {
  const getStatLabel = () => {
    switch (role) {
      case "programmer":
        return "Reviews";
      case "client":
        return "Projects approved";
      case "groupleader":
      default:
        return "Reviews";
    }
  };

  const getStatNumber = () => {
    switch (role) {
      case "programmer":
        return user.RProfile;
      case "client":
        return user.AProjects;
      case "groupleader":
      default:
        return user.RProfile;
    }
  };

  const getIcon = () => {
    switch (role) {
      case "programmer":
        return <MdOutlineReviews className="icon iconPf" />;
      case "client":
        return <VscGithubProject className="icon iconPf" />;
      case "groupleader":
      default:
        return <MdOutlineReviews className="icon iconPf" />;
    }
  };

  if (role === "support") {
    return <div>Support Section</div>;
  } else {
    return (
      <div className="sideTwoSectionOne">
        <span>
          <div className="block-container">
            <div className="btn-front">
              {getIcon()}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.3999 17.4999C11.8999 17.2999 11.2999 17.3999 11.0999 17.8999L9.29989 21.4999C8.99989 21.9999 9.19989 22.5999 9.69989 22.8999C9.79989 22.9999 9.99989 22.9999 10.1999 22.9999C10.5999 22.9999 10.8999 22.7999 11.0999 22.4999L12.8999 18.8999C13.0999 18.2999 12.8999 17.6999 12.3999 17.4999Z"
                  fill="url(#gradient-full)"
                />
                <path
                  d="M17 17.4999C16.5 17.2999 15.9 17.3999 15.7 17.8999L13.9 21.4999C13.7 21.9999 13.8 22.5999 14.3 22.7999C14.4 22.8999 14.6 22.8999 14.8 22.8999C15.2 22.8999 15.5 22.6999 15.7 22.3999L17.5 18.7999C17.7 18.2999 17.5 17.6999 17 17.4999Z"
                  fill="url(#gradient-full)"
                />
                <path
                  d="M7.89994 17.4999C7.39994 17.2999 6.79994 17.3999 6.59994 17.8999L4.79994 21.4999C4.59994 21.9999 4.69994 22.5999 5.19994 22.7999C5.29994 22.9999 5.49994 22.9999 5.59994 22.9999C5.99994 22.9999 6.29994 22.7999 6.49994 22.4999L8.29994 18.8999C8.59994 18.2999 8.39994 17.6999 7.89994 17.4999Z"
                  fill="url(#gradient-full)"
                />
                <path
                  d="M15.2 1C12.4 1 9.9 2.5 8.5 4.8C8 4.7 7.5 4.6 7 4.6C3.7 4.6 1 7.3 1 10.6C1 13.9 3.7 16.6 7 16.6H15.2C19.5 16.6 23 13.1 23 8.8C23 4.5 19.5 1 15.2 1Z"
                  fill="url(#gradient-full)"
                />
              </svg>
            </div>
          </div>
          <span className="stat">
            <MDBContainer>
              <Stat
                className="statChart2 statChart2Pf"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  background: "none",
                  boxShadow: "none",
                }}
              >
                <StatLabel>{getStatLabel()}</StatLabel>
                <StatNumber>{getStatNumber()}</StatNumber>
              </Stat>
            </MDBContainer>
          </span>
        </span>
      </div>
    );
  }
};

// Usage in your JSX:
export const DataProfile1 = ({ role, user }) => {
  return <>{renderContent(role, user)}</>;
};
export const DataProfile2 = ({ role, user }) => {
  return <>{renderContent2(role, user)}</>;
};

export function Chart3() {
  const [visitorCount, setVisitorCount] = useState(0);

  useEffect(() => {
    const fetchVisitorCount = async () => {
      await updateVisitorCountA();
      const count = await getVisitorCountA();
      setVisitorCount(count);
    };

    fetchVisitorCount();
  }, []);

  return (
    <MDBContainer>
      <Stat
        className="statChart2"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <StatLabel>Visit</StatLabel>
        <StatNumber>{visitorCount}</StatNumber>
        <StatHelpText>
          <StatArrow type="increase" />
          43.36%
        </StatHelpText>
      </Stat>
    </MDBContainer>
  );
}
