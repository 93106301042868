import { useContext, createContext, useState } from "react";
import {
  ChevronLast,
  ChevronFirst,
  Home,
  List,
  GitPullRequestCreateIcon,
  AppWindowIcon,
  MessageCircleWarningIcon,
  MessageCircleCodeIcon,
  MessageCircleIcon,
} from "lucide-react";
import "./Sidebar.css"; // Import your custom CSS file
import AvatarD from "./avatar";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import Logout from "../Logout";
import { TbLayoutDashboardFilled } from "react-icons/tb";
import { GoProjectRoadmap, GoProjectSymlink } from "react-icons/go";
import { Box, Divider } from "@chakra-ui/react";
import { LuUsers2 } from "react-icons/lu";

const SidebarContext = createContext();

export default function Sidebar({ children, user, onItemSelected }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <aside className="sidebar">
      <nav className="sidebar-nav">
        <div className="sidebar-header">
          {/* <button
            onClick={() => setExpanded((curr) => !curr)}
            className="toggle-button"
          >
            {expanded ? <ChevronFirst /> : <ChevronLast />}
          </button> */}
          <li className={`sidebar-item sidebar-item-avatar`}>
            <AvatarD user={user} />
            {!expanded && (
              <div className="tooltip">
                {user.firstName} {user.lastName}
              </div>
            )}
          </li>
        </div>
        <Box width="70%" className="notificationsDivider">
          <Divider orientation="horizontal" borderColor="transparent" />
        </Box>
        <SidebarContext.Provider value={{ expanded }}>
          {user.role === "master" ? (
            <ul className="sidebar-content">
              <SidebarItem
                icon={<TbLayoutDashboardFilled size="18px" color={children === "Dashboard" ? "white" : "grey"} />}
                text={"Dashboard"}
                active={children === "Dashboard" ? true : false}
                onClick={() => onItemSelected("Dashboard")}
              />
              <SidebarItem
                icon={<GoProjectRoadmap size="18px" color={children === "List" ? "white" : "grey"} />}
                text={"Projects"}
                active={children === "List" ? true : false}
                onClick={() => onItemSelected("List")}
              />
              <SidebarItem
                icon={<GoProjectSymlink size="18px" color={children === "ListProject" ? "white" : "grey"} />}
                text={"All Projects"}
                active={children === "ListProject" ? true : false}
                onClick={() => onItemSelected("ListProject")}
              />
              <SidebarItem
                icon={<LuUsers2 size="18px" color={children === "ListUsers" ? "white" : "grey"} />}
                text={"All Users"}
                active={children === "ListUsers" ? true : false}
                onClick={() => onItemSelected("ListUsers")}
              />
            </ul>
          ) : user.role === "groupleader" ? (
            <ul className="sidebar-content">
              <SidebarItem
                icon={<List size="18px" color={children === "Home" ? "white" : "grey"} />}
                text={"Projects"}
                active={children === "Home" ? true : false}
                onClick={() => onItemSelected("Home")}
              />
              <SidebarItem
                icon={<MessageCircleIcon size="18px" color={children === "Chats" ? "white" : "grey"} />}
                text={"Chats"}
                active={children === "Chats" ? true : false}
                onClick={() => onItemSelected("Chats")}
              />
            </ul>
          ) : user.role === "programmer" ? (
            <ul className="sidebar-content">
              <SidebarItem
                icon={<List size="18px" color={children === "Home" ? "white" : "grey"} />}
                text={"Projects"}
                active={children === "Home" ? true : false}
                onClick={() => onItemSelected("Home")}
              />
              <SidebarItem
                icon={<GitPullRequestCreateIcon size="18px" color={children === "Request" ? "white" : "grey"} />}
                text={"Leader Request"}
                active={children === "Request" ? true : false}
                onClick={() => onItemSelected("Request")}
              />
            </ul>
          ) : user.role === "support" ? (
            <ul className="sidebar-content">
              <SidebarItem
                icon={<List size="18px" color={children === "Home" ? "white" : "grey"} />}
                text={"Projects"}
                active={children === "Home" ? true : false}
                onClick={() => onItemSelected("Home")}
              />
              <SidebarItem
                icon={<MessageCircleWarningIcon size="18px" color={children === "Requests" ? "white" : "grey"} />}
                text={"Requests"}
                active={children === "Requests" ? true : false}
                onClick={() => onItemSelected("Requests")}
              />
            </ul>
          ) : user.role === "client" && (
            <ul className="sidebar-content">
              <SidebarItem
                icon={<List size="18px" color={children === "Home" ? "white" : "grey"} />}
                text={"Projects"}
                active={children === "Home" ? true : false}
                onClick={() => onItemSelected("Home")}
              />
              <SidebarItem
                icon={<MessageCircleIcon size="18px" color={children === "Chats" ? "white" : "grey"} />}
                text={"Chats"}
                active={children === "Chats" ? true : false}
                onClick={() => onItemSelected("Chats")}
              />
              <SidebarItem
                icon={<GitPullRequestCreateIcon size="18px" color={children === "Request" ? "white" : "grey"} />}
                text={"Programmer Request"}
                active={children === "Request" ? true : false}
                onClick={() => onItemSelected("Request")}
              />
            </ul>
          )}
        </SidebarContext.Provider>

        <Box width="80%" className="notificationsDivider">
          <Divider orientation="horizontal" borderColor="transparent" />
        </Box>

        <div className="sidebar-footer">
          <MDBContainer className="sidebar-footer-container">
            <Logout />
          </MDBContainer>
        </div>
      </nav>
    </aside>
  );
}

export function SidebarItem({ icon, text, active, onClick }) {
  const { expanded } = useContext(SidebarContext);

  return (
    <li className={`sidebar-item ${active ? "active" : ""}`} onClick={onClick}>
      {icon}
      {!expanded && (
        <div className="tooltip" style={{ width: "max-content" }}>
          {text}
        </div>
      )}
    </li>
  );
}
