import React, { useEffect, useState } from "react";
import { db, auth } from "../firebase";
import {
  collection,
  getDocs,
  updateDoc,
  getDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  MDBBadge,
  MDBBtn,
  MDBModalTitle,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";

function Table() {
  const [requests, setRequests] = useState([]);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    role: "",
  });

  useEffect(() => {
    const fetchRequests = async () => {
      const programmerQuerySnapshot = await getDocs(
        collection(db, "programmerRequests")
      );
      const leaderQuerySnapshot = await getDocs(
        collection(db, "leaderRequests")
      );

      const programmerRequestsList = programmerQuerySnapshot.docs.map(
        (doc) => ({
          id: doc.id,
          ...doc.data(),
        })
      );

      const leaderRequestsList = leaderQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const allRequests = [...programmerRequestsList, ...leaderRequestsList];
      setRequests(allRequests);

      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setUserData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchRequests();
  }, []);

  const handleApproval = async (requestId, approve, role) => {
    if (userData.role !== "support") {
      console.error("Unauthorized: Only support role can perform this action");
      return;
    }

    try {
      const collectionName =
        role === "programmer" ? "programmerRequests" : "leaderRequests";
      const requestDocRef = doc(db, collectionName, requestId);
      const requestData = requests.find((req) => req.id === requestId);

      if (!requestData) {
        console.log("Request data not found.");
        return;
      }

      if (approve) {
        await updateDoc(doc(db, "users", requestData.userId), {
          role: role,
          hasRequested: false,
          working: false,
          IProjects: 0,
          CProjects: 0,
          RProfile: 0,
        });
      }

      await updateDoc(requestDocRef, {
        role: approve ? role : "client",
        status: approve ? "approved" : "rejected",
      });

      if (!approve) {
        await deleteDoc(requestDocRef);
        await updateDoc(doc(db, "users", requestData.userId), {
          hasRequested: false,
        });
      }

      setRequests((prevRequests) =>
        prevRequests.filter((req) => req.id !== requestId)
      );
    } catch (error) {
      console.error("Error handling request:", error.message);
    }
  };

  return (
    <MDBTable borderless style={{ color: "white" }} align="middle">
      <MDBTableHead className="table-shut-down">
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Role</th>
          <th scope="col">Status</th>
          <th scope="col">Actions</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {requests.map((request, index) => (
          <tr
            key={request.id}
            className={index % 2 === 0 ? "table-active" : "table-shut-down"}
          >
            <td>
              <div className="d-flex align-items-center justify-content-center">
                <div>
                  <p className="fw-bold mb-1">{request.name}</p>
                </div>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center justify-content-center">
                <div>
                  <p className="fw-bold mb-1">{request.email}</p>
                </div>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center justify-content-center">
                <div>
                  <p className="fw-bold mb-1">{request.role}</p>
                </div>
              </div>
            </td>
            <td>
              <MDBBtn
                color={request.status === "approved" ? "success" : "warning"}
              >
                {request.status}
              </MDBBtn>
            </td>
            <td>
              {request.status !== "approved" && (
                <>
                  <MDBBtn
                    color="link"
                    rounded
                    size="sm"
                    onClick={() =>
                      handleApproval(request.id, true, request.role)
                    }
                  >
                    Approve
                  </MDBBtn>
                  <MDBBtn
                    color="link"
                    rounded
                    size="sm"
                    onClick={() =>
                      handleApproval(request.id, false, request.role)
                    }
                  >
                    Reject
                  </MDBBtn>
                </>
              )}
            </td>
          </tr>
        ))}
      </MDBTableBody>
    </MDBTable>
  );
}

export default Table;
