import { MDBBtn, MDBCol, MDBListGroupItem, MDBTooltip } from "mdb-react-ui-kit";
import React from "react";
import "./nLeader.css";
import { useNavigate } from "react-router-dom";

export default function Notification({
  userName,
  publicationDate,
  status,
  leader,
}) {
  return (
    <MDBCol xl={12} lg={6} className="notificationLeaderCol">
      <div className="notificationLeaderContainer">
        <div className="notificationLeaderContent">
          <span className="notificationLeaderHeader">
            <div className="notificationLeaderInfo">
              <div className="notificationLeaderText">
                <p className="fw-bold mb-1">{userName}</p>
                <p className="text-muted mb-0">{publicationDate}</p>
              </div>
              <div>
                <MDBTooltip tag="a" wrapperProps={{ href: "#" }} title={leader}>
                  <MDBBtn
                    outline
                    disabled
                    color={status === "approved" ? "success" : "warning"}
                    size="sm"
                    type="button"
                    className="notificationLeaderBtn"
                  >
                    {status}
                  </MDBBtn>
                </MDBTooltip>
              </div>
            </div>
          </span>
        </div>
      </div>
    </MDBCol>
  );
}

export function OnlineUser({ id, user }) {
  const navigate = useNavigate();
  return (
    <MDBCol
      xl={12}
      lg={6}
      key={id}
      className="notificationLeaderCol"
      onClick={() => {
        navigate(`/profile/${user.id}`);
      }}
      style={{ cursor: "pointer" }}
    >
      <div className="notificationLeaderContainer">
        <div className="notificationLeaderContent">
          <span className="notificationLeaderHeader">
            <div className="notificationLeaderInfo">
              <div className="notificationLeaderText">
                <p className="fw-bold mb-1">
                  {user.firstName} {user.lastName}
                </p>
                <p className="text-muted mb-0">{user.email}</p>
              </div>
              <div>
                <MDBTooltip tag="a" wrapperProps={{ href: "#" }}>
                  <MDBBtn
                    outline
                    disabled
                    color="light"
                    size="sm"
                    type="button"
                    className="notificationLeaderBtn"
                  >
                    {user.role}
                  </MDBBtn>
                </MDBTooltip>
              </div>
            </div>
          </span>
        </div>
      </div>
    </MDBCol>
  );
}

export const Activities = ({ project }) => {
  return (
    <MDBListGroupItem
      key={project.id}
      className="d-flex justify-content-between align-items-center listGroupItem"
    >
      <div className="d-flex align-items-center">
        <div className="ms-3">
          <p className="fw-bold mb-1">
            {project.leader ? (
              project.leader
            ) : (
              <span className="notYet">Not Yet</span>
            )}
          </p>
          <figcaption className="blockquote-footer mb-0">
            Project:{" "}
            <cite title="Source Title">{`${project.firstName} ${project.lastName}`}</cite>
          </figcaption>
        </div>
      </div>
      <MDBBtn
        color={
          project.work === "still"
            ? "danger"
            : project.work === "on it"
            ? "warning"
            : "success"
        }
        size="sm"
        type="button"
      >
        {project.work}
      </MDBBtn>
    </MDBListGroupItem>
  );
};
