import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase"; // Import your Firestore and auth instances
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Logout from "../Logout";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import Header from "../contents/header";
import ProgrammerRegister from "../ProgrammerRegister";
import ProjectList from "../projectList/projectList";
import NotificationList from "../notifications/notificationList";
import useCurrentUser from "../currentUser/currentuser";
import Chat from "../projectList/chat";
import Loading from "../loading";
import ChatList from "../projectList/chatlist";
import AvatarD from "../contents/avatar";
import ShapeSection from "./shapeSection";

function ClientDashboard() {
  const navigate = useNavigate();
  const userData = useCurrentUser();
  const [activePage, setActivePage] = useState("Home");

  const renderContent = () => {
    switch (activePage) {
      case "Home":
        return (
          <ShapeSection
            title={"Dashboard"}
            compenent={<ProjectList />}
            userData={userData}
          />
        );
      case "Chats":
        return (
          <ShapeSection
            title={"Chat"}
            compenent={<ChatList />}
            userData={userData}
          />
        );
      case "Request":
        return (
          <ShapeSection
            title={"Request"}
            compenent={<ProgrammerRegister />}
            userData={userData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="all-things-here">
      <Header
        children={activePage}
        user={userData}
        onItemSelected={setActivePage}
      />
      {renderContent()}
      <NotificationList />
    </div>
  );
}

export default ClientDashboard;
