import { MDBCard, MDBCardBody, MDBCol } from "mdb-react-ui-kit";
import React from "react";
import DisplayPreported from "./displayPreported";
import "./report.css";

export default function ReportI({
  userName,
  userReportedName,
  userReportedId,
  publicationId,
  userId,
}) {
  return (
    <MDBCol xl={12} lg={6} className="reportCol">
      <div className="reportContainer">
        <div className="reportContent">
          <span className="reportDetails">
            <div className="reportHeader d-flex align-items-center">
              <div className="ms-3 reportText">
                <p className="fw-bold mb-1">Reported by: {userName}</p>
                <p className="text-muted mb-0">
                  Project released by: {userReportedName}
                </p>
              </div>
            </div>
            <DisplayPreported
              publicationId={publicationId}
              userId={userId}
              reportUserId={userReportedId}
            />
          </span>
        </div>
      </div>
    </MDBCol>
  );
}
