import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getVisitorCounts, updateVisitorCount } from "../firebase";

const ApexChart = ({ timeFrame }) => {
  const [visitorData, setVisitorData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await updateVisitorCount();
        const data = await getVisitorCounts(timeFrame);
        setVisitorData(data);
      } catch (error) {
        console.error("Error fetching visitor data:", error);
        setVisitorData([]); // Set a default empty state in case of error
      }
    };

    fetchData();
  }, [timeFrame]);

  // Map visitorData directly to get categories and series data
  const categories = visitorData.map((entry) => entry.date);
  const seriesData = visitorData.map((entry) => entry.count);

  const series = [
    {
      name: "Visitors",
      data: seriesData,
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
    },
    stroke: {
      width: 5,
      curve: "smooth",
    },
    xaxis: {
      type: timeFrame === "Yearly" ? "category" : "datetime",
      categories: categories,
      tickAmount: 10,
      labels: {
        formatter: function (value, timestamp, opts) {
          if (timeFrame === "Yearly") {
            return value; // For Yearly, keep the value as is
          }
          return new Date(timestamp).toLocaleDateString(
            undefined,
            timeFrame === "Monthly" ? { month: "short" } : { day: "2-digit" }
          );
        },
      },
    },
    yaxis: {
      labels: {
        show: false, // Hide the y-axis labels
      },
      axisBorder: {
        show: false, // Hide the y-axis border
      },
      axisTicks: {
        show: false, // Hide the y-axis ticks
      },
    },
    grid: {
      show: false, // Remove horizontal grid lines
    },
    annotations: {
      xaxis: [
        {
          y: 0, // Line position at the bottom of the chart
          borderColor: "#FF4560", // Line color
          borderWidth: 2,
          label: {
            text: "",
            style: {
              color: "#FF4560",
              background: "#fff",
            },
          },
          // Gradient effect
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              gradientToColors: ["#FF4560"],
              shadeIntensity: 1,
              type: "horizontal",
              opacityFrom: 1,
              opacityTo: 0.1, // End opacity
              stops: [0, 100],
            },
          },
        },
      ],
      yaxis: [],
    },
    title: {
      text: ``,
      align: "left",
      style: {
        fontSize: "16px",
        color: "#666",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#FDD835"],
        shadeIntensity: 1,
        type: "horizontal",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
