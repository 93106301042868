import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { db } from "../firebase";

export default function SetProjectTable({
  displayFilter,
  sortFilter,
  groupFilter,
  onUpdate, // Pass a callback to update the project list when data changes
  limitCall,
}) {
  let q = collection(db, "projects");

  // Apply Display Filter
  if (displayFilter && displayFilter !== "all") {
    q = query(q, where("work", "==", displayFilter));
  }

  // Apply Sort Filter
  if (sortFilter) {
    q = query(q, orderBy(sortFilter.field, sortFilter.direction));
  }

  if (limitCall) {
    q = query(q, limit(limitCall));
  }

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const projectData = [];
    querySnapshot.forEach((doc) => {
      projectData.push({ id: doc.id, ...doc.data() });
    });

    // Group Projects based on groupFilter
    const groupedProjects = groupProjects(projectData, groupFilter);

    // Call the callback to update the project list
    if (onUpdate) onUpdate(groupedProjects);
  });

  // Return the unsubscribe function to stop listening when no longer needed
  return unsubscribe;
}

// Group Projects based on groupFilter
const groupProjects = (projects, groupFilter) => {
  if (!groupFilter) return projects;

  const grouped = projects.reduce((acc, project) => {
    const key = project[groupFilter] || "Others";
    if (!acc[key]) acc[key] = [];
    acc[key].push(project);
    return acc;
  }, {});

  // Flatten grouped projects back into an array
  return Object.values(grouped).flat();
};
