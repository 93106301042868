import React, { useState } from "react";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBIcon,
} from "mdb-react-ui-kit";
import { ChevronDownIcon } from "lucide-react";
import {
  Button,
  MenuButton,
  MenuItem,
  MenuList,
  Menu,
  Divider,
  Box,
} from "@chakra-ui/react";
import Projects from "./projects";
import CardView from "./cardview";
import CalendarView from "./calendarview";
import KanbanView from "./kanbanview";

export default function AllProjects() {
  const [iconsActive, setIconsActive] = useState("tab1");
  const [displayFilter, setDisplayFilter] = useState("all");
  const [sortFilter, setSortFilter] = useState({
    field: "firstName",
    direction: "asc",
    asc: "Name",
  });
  const [groupFilter, setGroupFilter] = useState("leader");

  const handleIconsClick = (value) => {
    if (value === iconsActive) {
      return;
    }
    setIconsActive(value);
  };

  return (
    <div
      className="dashboardSection"
      style={{ height: "100%", flexDirection: "column" }}
    >
      <div className="AllProjectsSection">
        <div className="states usersSection">
          <MDBTabs
            fill
            className="mb-3 tabsMDB"
            style={{
              padding: "3% 8%",
              display: "flex",
              gap: "3%",
              flexWrap: "nowrap",
            }}
          >
            <MDBTabsItem>
              <MDBTabsLink
                className="tabBtn"
                onClick={() => handleIconsClick("tab1")}
                active={iconsActive === "tab1"}
              >
                List
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                className="tabBtn"
                onClick={() => handleIconsClick("tab2")}
                active={iconsActive === "tab2"}
              >
                Calendar
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                className="tabBtn"
                onClick={() => handleIconsClick("tab3")}
                active={iconsActive === "tab3"}
              >
                Cards
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                className="tabBtn"
                onClick={() => handleIconsClick("tab4")}
                active={iconsActive === "tab4"}
              >
                Kanban
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </div>
        <div className="filter" style={{
              padding: "1%",
              display: "flex",
              gap: "3%",
              flexWrap: "nowrap",
            }}>
          <Menu>
            <MenuButton
              className="shadow-0"
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              <figcaption className="blockquote-footer mb-0">
                {displayFilter}
              </figcaption>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => setDisplayFilter("all")}>
                All Projects
              </MenuItem>
              <MenuItem onClick={() => setDisplayFilter("still")}>
                Waiting
              </MenuItem>
              <MenuItem onClick={() => setDisplayFilter("on it")}>
                In Progress
              </MenuItem>
              <MenuItem onClick={() => setDisplayFilter("completed")}>
                Completed
              </MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton
              className="shadow-0"
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              <figcaption className="blockquote-footer mb-0">
                {sortFilter.asc}
              </figcaption>
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() =>
                  setSortFilter({
                    field: "firstName",
                    direction: "asc",
                    asc: "Name",
                  })
                }
              >
                Name
              </MenuItem>
              <MenuItem
                onClick={() =>
                  setSortFilter({
                    field: "publicationDate",
                    direction: "asc",
                    asc: "Date",
                  })
                }
              >
                Date
              </MenuItem>
              <MenuItem
                onClick={() =>
                  setSortFilter({
                    field: "status",
                    direction: "asc",
                    asc: "Status",
                  })
                }
              >
                Status
              </MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton
              className="shadow-0"
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              <figcaption className="blockquote-footer mb-0">
                {groupFilter}
              </figcaption>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => setGroupFilter("leader")}>
                Leader
              </MenuItem>
              <MenuItem onClick={() => setGroupFilter("status")}>
                Status
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>
      <Box
        width="90%"
        alignSelf="center"
        bgGradient="linear(to-r, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)"
        height="1px"
        margin="0 0 10px 0"
      >
        <Divider orientation="horizontal" borderColor="transparent" />
      </Box>
      <div className="AllProjectsBody">
        <MDBTabsContent>
          <MDBTabsPane open={iconsActive === "tab1"}>
            <Projects
              displayFilter={displayFilter}
              sortFilter={sortFilter}
              groupFilter={groupFilter}
            />
          </MDBTabsPane>
          <MDBTabsPane open={iconsActive === "tab2"}>
            <CalendarView
              displayFilter={displayFilter}
              sortFilter={sortFilter}
              groupFilter={groupFilter}
            />
          </MDBTabsPane>
          <MDBTabsPane open={iconsActive === "tab3"}>
            <CardView
              displayFilter={displayFilter}
              sortFilter={sortFilter}
              groupFilter={groupFilter}
            />
          </MDBTabsPane>
          <MDBTabsPane open={iconsActive === "tab4"}>
            {/* <KanbanView
              displayFilter={displayFilter}
              sortFilter={sortFilter}
              groupFilter={groupFilter}
            /> */}
          </MDBTabsPane>
        </MDBTabsContent>
      </div>
    </div>
  );
}
