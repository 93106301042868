import { useState } from "react";
import Header from "../contents/header";
import useCurrentUser from "../currentUser/currentuser";
import AllProjects from "../masterFL/allprojects";
import ReportList from "../reports/reportList";
import Table from "../masterFL/table";
import { MDBContainer } from "mdb-react-ui-kit";
import ShapeSection from "./shapeSection";

function SupportMB() {
  const userData = useCurrentUser();
  const [activePage, setActivePage] = useState("Home");

  const renderContent = () => {
    switch (activePage) {
      case "Home":
        return (
          <ShapeSection
            title={"Dashboard"}
            compenent={<AllProjects />}
            userData={userData}
          />
        );
      case "Requests":
        return (
          <ShapeSection
            title={"Requests"}
            compenent={<Table />}
            userData={userData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="all-things-here">
      <Header
        children={activePage}
        user={userData}
        onItemSelected={setActivePage}
      />
      {renderContent()}
      <ReportList />
    </div>
  );
}

export default SupportMB;
