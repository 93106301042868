import { Box, Divider, FormControl, FormLabel, Switch } from "@chakra-ui/react";
import { doc, updateDoc } from "firebase/firestore";
import { reauthenticateWithCredential, updatePassword } from "firebase/auth";
import {
  MDBBtn,
  MDBInput,
  MDBInputGroup,
  MDBValidation,
  MDBValidationItem,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { FaRegEdit } from "react-icons/fa";
import { EmailAuthProvider } from "firebase/auth/web-extension";

export default function EditProfile({ userData, user }) {
  const [isFNEditing, setIsFNEditing] = useState(false);
  const [isLNEditing, setIsLNEditing] = useState(false);
  const [isBDEditing, setIsBDEditing] = useState(false);
  const [isPasswordEditing, setIsPasswordEditing] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setbirthday] = useState(new Date());
  const [newPassword, setNewPassword] = useState("");
  const [showEmail, setShowEmail] = useState(userData.showEmail);
  const [showPhone, setShowPhone] = useState(userData.showPhone);
  const [showBirthday, setshowBirthday] = useState(userData.showBirthday);
  const [error, setError] = useState(null);
  const [reauthPassword, setReauthPassword] = useState(""); // For the re-auth modal
  const [showModal, setShowModal] = useState(false); // Modal state

  useEffect(() => {
    if (userData) {
      setFirstName(userData.firstName);
      setLastName(userData.lastName);
      setShowEmail(userData.showEmail);
      setShowPhone(userData.showPhone);
      setshowBirthday(userData.showBirthday);
    }
  }, [userData]);

  const toggleModal = () => setShowModal(!showModal);

  const handleSwitch = async (value, field) => {
    try {
      if (user) {
        const docRef = doc(db, "users", user.uid);

        await updateDoc(docRef, {
          [field]: value,
        });

        switch (field) {
          case "showEmail":
            setShowEmail(value);
            break;
          case "showPhone":
            setShowPhone(value);
            break;
          case "showBirthday":
            setshowBirthday(value);
            break;
          default:
            break;
        }

        setError(null);
      }
    } catch (e) {
      setError("Error updating profile.");
    }
  };

  const handleChanges = async (value, field) => {
    try {
      if (user) {
        const docRef = doc(db, "users", user.uid);

        await updateDoc(docRef, {
          [field]: value,
        });

        switch (field) {
          case "firstName":
            setFirstName(value);
            setIsFNEditing(false);
            break;
          case "lastName":
            setLastName(value);
            setIsLNEditing(false);
            break;
          case "birthday":
            setbirthday(value);
            setIsBDEditing(false);
            break;
          default:
            break;
        }

        setError(null);
      }
    } catch (e) {
      setError("Error updating profile.");
    }
  };

  const handlePasswordChange = async () => {
    try {
      if (!user) {
        setError("No authenticated user found.");
        return;
      }

      if (newPassword.length < 6) {
        setError("Password must be at least 6 characters long.");
        return;
      }

      try {
        // Attempt to update password
        await updatePassword(user, newPassword);
        setNewPassword(""); // Clear password input after success
        setIsPasswordEditing(false); // Exit edit mode
        setError(null); // Clear any previous errors
      } catch (error) {
        if (error.code === "auth/requires-recent-login") {
          setShowModal(true);
        } else {
          console.error("Error updating password:", error);
          setError("Error updating password. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error during password change:", error);
      setError("Error updating password. Please try again.");
    }
  };

  const handleReauthentication = async () => {
    try {
      const credential = EmailAuthProvider.credential(
        user.email,
        reauthPassword
      );
      console.log("Reauthenticating with credentials: ", credential);

      await reauthenticateWithCredential(user, credential);

      // Retry updating the password after re-authentication
      await updatePassword(user, newPassword);
      setNewPassword("");
      setIsPasswordEditing(false);
      setReauthPassword(""); // Clear re-auth password input
      setShowModal(false); // Close the modal after success
      setError(null);
    } catch (error) {
      console.error("Error during reauthentication:", error);
      setError("Re-authentication failed. Please try again.");
    }
  };

  const handleCancelEditing = (field) => {
    switch (field) {
      case "firstName":
        setFirstName(userData.firstName);
        setIsFNEditing(false);
        break;
      case "lastName":
        setLastName(userData.lastName);
        setIsLNEditing(false);
        break;
      case "password":
        setNewPassword("");
        setIsPasswordEditing(false);
        break;
      case "birthday":
        setbirthday("");
        setIsBDEditing(false);
        break;
      default:
        break;
    }
  };

  return (
    <div className="edit-profile-div">
      {/* Switches for email and phone */}
      <span className="side-edit-1">
        <FormControl
          display="flex"
          alignItems="center"
          w="100%"
          justifyContent="space-between"
        >
          <FormLabel htmlFor="display-email" mb="0">
            Display email:
          </FormLabel>
          <Switch
            size="md"
            id="display-email"
            isChecked={showEmail}
            onChange={() => handleSwitch(!showEmail, "showEmail")}
          />
        </FormControl>
        <FormControl
          display="flex"
          alignItems="center"
          w="100%"
          justifyContent="space-between"
        >
          <FormLabel htmlFor="display-phone" mb="0">
            Display phone number:
          </FormLabel>
          <Switch
            size="md"
            id="display-phone"
            isChecked={showPhone}
            onChange={() => handleSwitch(!showPhone, "showPhone")}
          />
        </FormControl>
        <FormControl
          display="flex"
          alignItems="center"
          w="100%"
          justifyContent="space-between"
        >
          <FormLabel htmlFor="display-phone" mb="0">
            Display birth day:
          </FormLabel>
          <Switch
            size="md"
            id="display-phone"
            isChecked={showBirthday}
            onChange={() => handleSwitch(!showBirthday, "showBirthday")}
          />
        </FormControl>
      </span>

      <Box
        height="100%"
        alignSelf="center"
        bgGradient="linear(to-b, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)"
        width="1px"
        margin="0 10px"
      >
        <Divider orientation="vertical" borderColor="transparent" />
      </Box>

      {/* Form for name and password */}
      <span className="side-edit-2">
        <span className="spanInputsProfile">
          <MDBValidation
            className="row g-3 spanInputsProfile"
            isValidated
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              width: "100%",
              gap: "2%",
            }}
          >
            {/* First Name */}
            <MDBValidationItem
              className="col-md-6"
              style={{ width: "100%" }}
              feedback="Invalid first name."
              invalid
            >
              <MDBInputGroup className="mb-3">
                <MDBInput
                  label="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  id="firstName"
                  labelStyle={{ color: "#ecdfcc" }}
                  type="text"
                  disabled={!isFNEditing}
                  value={isFNEditing ? firstName : userData.firstName}
                />
                {isFNEditing ? (
                  <>
                    <MDBBtn
                      color="success"
                      size="sm"
                      style={{ width: "max-content" }}
                      disabled={!firstName || firstName === userData.firstName}
                      onClick={() => handleChanges(firstName, "firstName")}
                    >
                      Save
                    </MDBBtn>
                    <MDBBtn
                      onClick={() => handleCancelEditing("firstName")}
                      outline
                      color="danger"
                    >
                      Cancel
                    </MDBBtn>
                  </>
                ) : (
                  <MDBBtn
                    children={<FaRegEdit fontSize="20px" />}
                    onClick={() => setIsFNEditing(true)}
                    color="white"
                    outline
                  ></MDBBtn>
                )}
              </MDBInputGroup>
            </MDBValidationItem>

            {/* Last Name */}
            <MDBValidationItem
              className="col-md-6"
              style={{ width: "100%" }}
              feedback="Invalid last name."
              invalid
            >
              <MDBInputGroup className="mb-3">
                <MDBInput
                  label="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  id="lastName"
                  labelStyle={{ color: "#ecdfcc" }}
                  type="text"
                  disabled={!isLNEditing}
                  value={isLNEditing ? lastName : userData.lastName}
                />
                {isLNEditing ? (
                  <>
                    <MDBBtn
                      color="success"
                      disabled={!lastName || lastName === userData.lastName}
                      onClick={() => handleChanges(lastName, "lastName")}
                    >
                      Save
                    </MDBBtn>
                    <MDBBtn
                      onClick={() => handleCancelEditing("lastName")}
                      outline
                      color="danger"
                    >
                      Cancel
                    </MDBBtn>
                  </>
                ) : (
                  <MDBBtn
                    children={<FaRegEdit fontSize="20px" />}
                    onClick={() => setIsLNEditing(true)}
                    color="white"
                    outline
                  ></MDBBtn>
                )}
              </MDBInputGroup>
            </MDBValidationItem>

            {/* Password */}
            <MDBValidationItem
              className="col-md-6"
              style={{ width: "100%" }}
              feedback="Invalid password."
              invalid
            >
              <MDBInputGroup className="mb-3">
                <MDBInput
                  label="New Password"
                  id="password"
                  labelStyle={{ color: "#ecdfcc" }}
                  type="password"
                  disabled={!isPasswordEditing}
                  minLength={6}
                  onChange={(e) => setNewPassword(e.target.value)}
                  value={newPassword}
                />
                {isPasswordEditing ? (
                  <>
                    <MDBBtn
                      color="success"
                      disabled={!newPassword}
                      onClick={handlePasswordChange}
                    >
                      Save
                    </MDBBtn>
                    <MDBBtn
                      onClick={() => handleCancelEditing("password")}
                      outline
                      color="danger"
                    >
                      Cancel
                    </MDBBtn>
                  </>
                ) : (
                  <MDBBtn
                    children={<FaRegEdit fontSize="20px" />}
                    onClick={() => setIsPasswordEditing(true)}
                    color="white"
                    outline
                  ></MDBBtn>
                )}
              </MDBInputGroup>
            </MDBValidationItem>

            {/* Birth day */}
            <MDBValidationItem
              className="col-md-6"
              style={{ width: "100%" }}
              feedback="Invalid birthday."
              invalid
            >
              <MDBInputGroup className="mb-3">
                <MDBInput
                  label="Birthday"
                  onChange={(e) => setbirthday(e.target.value)}
                  required
                  id="Birthday"
                  labelStyle={{ color: "#ecdfcc" }}
                  type="date"
                  disabled={!isBDEditing}
                  value={isBDEditing ? birthday : userData.birthday}
                />
                {isBDEditing ? (
                  <>
                    <MDBBtn
                      color="success"
                      disabled={!birthday || birthday === userData.birthday}
                      onClick={() => handleChanges(birthday, "birthday")}
                    >
                      Save
                    </MDBBtn>
                    <MDBBtn
                      onClick={() => handleCancelEditing("birthday")}
                      outline
                      color="danger"
                    >
                      Cancel
                    </MDBBtn>
                  </>
                ) : (
                  <MDBBtn
                    children={<FaRegEdit fontSize="20px" />}
                    onClick={() => setIsBDEditing(true)}
                    color="white"
                    outline
                  ></MDBBtn>
                )}
              </MDBInputGroup>
            </MDBValidationItem>
          </MDBValidation>
        </span>
      </span>

      {/* Modal for re-authentication */}
      <MDBModal show={showModal} setShow={setShowModal} tabIndex="-1">
        <MDBModalHeader>Re-authenticate</MDBModalHeader>
        <MDBModalBody>
          <MDBInput
            label="Enter current password"
            type="password"
            value={reauthPassword}
            onChange={(e) => setReauthPassword(e.target.value)}
            required
          />
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="danger" onClick={toggleModal}>
            Cancel
          </MDBBtn>
          <MDBBtn color="success" onClick={handleReauthentication}>
            Re-authenticate
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* {error && <div className="error-message">{error}</div>} */}
    </div>
  );
}
