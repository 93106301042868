import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"; // Import Framer Motion

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
};

const HomePage = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/LRU");
  };

  return (
    <div className="parent-home">
      <div className="homepage-container">
        {/* Header Section */}
        <motion.header
          className="homepage-header"
          initial="initial"
          animate="animate"
          variants={fadeInUp}
        >
          <h1>Welcome to Web Weave</h1>
        </motion.header>

        {/* Main Section */}
        <main className="homepage-main">
          {/* Introduction */}
          <motion.section
            className="homepage-section"
            initial="initial"
            whileInView="animate"
            variants={fadeInUp}
          >
            <h2>Introduction</h2>
            <p>
              Web Weave brings clients, programmers, project leaders, and
              support teams together for seamless project management and
              collaboration.
            </p>
          </motion.section>

          {/* Roles and Opportunities */}
          <motion.section
            className="homepage-section"
            initial="initial"
            whileInView="animate"
            variants={fadeInUp}
          >
            <h2>Roles and Opportunities</h2>
            <ul>
              <li>
                <strong>Client:</strong> Post projects, review bids, and manage
                your project efficiently.
              </li>
              <li>
                <strong>Programmer:</strong> Bid on projects and complete tasks
                assigned by the leader.
              </li>
              <li>
                <strong>Leader:</strong> Manage teams, assign tasks, and ensure
                project success.
              </li>
              <li>
                <strong>Support:</strong> Mediate disputes and monitor platform
                compliance.
              </li>
              <li>
                <strong>Master:</strong> Oversee the entire platform and manage
                policies.
              </li>
            </ul>
          </motion.section>

          {/* Platform Content */}
          <motion.section
            className="homepage-section"
            initial="initial"
            whileInView="animate"
            variants={fadeInUp}
          >
            <h2>Platform Features</h2>
            <p>
              From efficient project management to real-time messaging and
              secure payment processing, Web Weave provides all the tools you
              need for project success.
            </p>
          </motion.section>

          {/* Call to Action */}
          <motion.section
            className="homepage-section"
            initial="initial"
            whileInView="animate"
            variants={fadeInUp}
          >
            <h2>Ready to Start?</h2>
            <p>
              Join Web Weave today and start managing your projects like a pro!
            </p>
            <button className="homepage-button" onClick={handleGetStarted}>
              Get Started
            </button>
          </motion.section>
        </main>

        {/* Footer Section */}
        <footer className="homepage-footer">
          <p>© 2024 Web Weave. All Rights Reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default HomePage;
