import { auth, db } from "../firebase";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";

function useCurrentUser() {
  const [userData, setUserData] = useState({
    uid: "",
    firstName: "",
    lastName: "",
    role: "",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const { uid } = user;
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setUserData({ uid, ...docSnap.data() });
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchUserData();
  }, []);

  return userData;
}

export default useCurrentUser;
