import { MDBContainer } from "mdb-react-ui-kit";
import React from "react";
import AvatarD from "../contents/avatar";

export default function ShapeSection({ title, compenent, userData }) {
  return (
    <div className="DashboardDivMaster">
      <div
        style={{
          display: "flex",
          gap: "15px",
          flexDirection: "column",
          width: "100%",
          height: "95%",
        }}
      >
        <MDBContainer
          style={{
            display: "flex",
            height: "100%",
            padding: "0",
            gap: "10px",
            flexDirection: "column",
          }}
        >
          <div className="dashboardHeader">
            <h1 className="title-h1">{title}</h1>
          </div>
          <div className="projectSection">{compenent}</div>
        </MDBContainer>
      </div>
    </div>
  );
}
