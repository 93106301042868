import React, { useState, useRef, useEffect } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBInput,
  MDBPopover,
  MDBPopoverBody,
  MDBTextArea,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";
import { BanIcon, EditIcon, TrashIcon } from "lucide-react";
import useCurrentUser from "../currentUser/currentuser";
import { createNewChat, db } from "../firebase";
import {
  updateDoc,
  doc,
  setDoc,
  getDoc,
  where,
  getDocs,
  writeBatch,
  collection,
  query,
} from "firebase/firestore";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "./project.css";

const formatTextWithLineBreaks = (text) => {
  return text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

function Project({
  clientN,
  dateP,
  stat,
  leader,
  puid,
  body,
  subject,
  id,
  minAmount = "*",
  maxAmount = "*",
}) {
  const [hovered, setHovered] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isTruncatable, setIsTruncatable] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newBody, setNewBody] = useState(body);
  const [newSubject, setNewSubject] = useState(subject);
  const [newMinAmount, setNewMinAmount] = useState(minAmount);
  const [newMaxAmount, setNewMaxAmount] = useState(maxAmount);
  const [hasReported, setHasReported] = useState(false);
  const textRef = useRef(null);
  const navigate = useNavigate();

  const userData = useCurrentUser();

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseInt(
        window.getComputedStyle(textRef.current).lineHeight
      );
      const maxHeight = lineHeight * 3; // For 3 lines
      if (textRef.current.scrollHeight > maxHeight) {
        setIsTruncatable(true);
      } else {
        setIsTruncatable(false);
      }
    }
  }, []);

  useEffect(() => {
    const checkIfReported = async () => {
      try {
        const reportId = `${id}_${userData.uid}`;
        const reportRef = doc(db, "reports", reportId);
        const reportDoc = await getDoc(reportRef);
        if (reportDoc.exists()) {
          setHasReported(true);
        }
      } catch (error) {
        console.error("Error checking report status: ", error);
      }
    };

    checkIfReported();
  }, [id, userData.uid]);

  const handleHover = () => {
    setHovered(!hovered);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      const projectRef = doc(db, "projects", id);
      await updateDoc(projectRef, {
        body: newBody,
        subject: newSubject,
        minAmount: newMinAmount,
        maxAmount: newMaxAmount,
      });
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleDelete = async () => {
    const batch = writeBatch(db);

    try {
      // 1. Query all chat documents that contain the projectId
      const chatsRef = collection(db, "chats");
      const chatsQuery = query(chatsRef, where("projectId", "==", id));
      const chatDocs = await getDocs(chatsQuery);

      // 2. Collect all chat IDs and leader IDs
      const chatIds = chatDocs.docs.map((doc) => doc.id);
      const leaderIds = new Set(
        chatDocs.docs.map((doc) => doc.data().leaderId)
      );

      // 3. Query and delete all messages related to the collected chat IDs
      for (const chatId of chatIds) {
        const messagesRef = collection(db, "messages");
        const messagesQuery = query(messagesRef, where("chatId", "==", chatId));
        const messageDocs = await getDocs(messagesQuery);

        messageDocs.forEach((messageDoc) => {
          batch.delete(messageDoc.ref);
        });

        // Delete the chat document
        batch.delete(doc(db, "chats", chatId));
      }

      // 4. Update leader status in the 'users' collection
      for (const leaderId of leaderIds) {
        const leaderRef = doc(db, "users", leaderId);
        batch.update(leaderRef, { working: false });
      }

      // 5. Query and delete notifications related to the projectId
      const notificationsRef = collection(db, "notifications");
      const notificationsQuery = query(
        notificationsRef,
        where("projectId", "==", id)
      );
      const notificationDocs = await getDocs(notificationsQuery);

      notificationDocs.forEach((notificationDoc) => {
        batch.delete(notificationDoc.ref);
      });

      // 6. Delete the project document
      batch.delete(doc(db, "projects", id));

      // 7. Decrement the user's MProjects count
      const userRef = doc(db, "users", puid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const currentMProjects = userSnap.data().MProjects || 0;
        const newMProjects = Math.max(0, currentMProjects - 1);
        batch.update(userRef, { MProjects: newMProjects });
      }

      // 8. Commit the batch operation
      await batch.commit();
      console.log(
        "Project, related data, notifications, and MProjects count successfully updated!"
      );
    } catch (error) {
      console.error("Error deleting project and related data: ", error);
    }
  };

  const handleReport = async () => {
    try {
      const reportId = `${id}_${userData.uid}`;
      await setDoc(doc(db, "reports", reportId), {
        publicationId: id,
        reportedById: userData.uid,
        publicationUserId: puid,
        timestamp: new Date(),
      });
      setHasReported(true);
      console.log("Report successfully submitted!");
    } catch (error) {
      console.error("Error submitting report: ", error);
    }
  };

  const handleApprove = async () => {
    try {
      const projectRef = doc(db, "projects", id);
      const userRef = doc(db, "users", userData.uid);

      // Update project with leader details and status
      await updateDoc(projectRef, {
        leader: `${userData.firstName} ${userData.lastName}`,
        leaderId: userData.uid,
        status: "approved",
        work: "on it",
      });

      // Update the leader's status to indicate they are working on a project
      await updateDoc(userRef, {
        working: true,
      });

      const userRefC = doc(db, "users", puid);
      // Fetch the current value of AProjects (approved projects count)
      const userSnap = await getDoc(userRefC);

      if (userSnap.exists()) {
        const currentAProjects = userSnap.data().AProjects || 0;
        const newAProjects = currentAProjects + 1;

        // Update the user's AProjects count
        await updateDoc(userRefC, {
          AProjects: newAProjects,
        });
      }

      // Create chat between client and leader
      createNewChat(puid, userData.uid, id);

      console.log(
        "Project approved, user status updated, AProjects incremented, and chat created!"
      );
    } catch (error) {
      console.error("Error approving project and updating user data: ", error);
    }
  };

  return (
    <MDBContainer className="projectusers-container">
      <MDBContainer className="projectusers-header">
        <div>
          <span
            className="projectusers-client-name"
            onClick={() => {
              navigate(`/profile/${puid}`);
            }}
          >
            {clientN}
          </span>
          <span className="projectusers-client-date">
            {new Date(
              dateP.seconds * 1000 + dateP.nanoseconds / 1000000
            ).toLocaleDateString("en-US", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })}
          </span>
        </div>
        {userData.role !== "groupleader" && userData.role !== "programmer" ? (
          <MDBDropdown dropright group className="shadow-0">
            <MDBDropdownToggle
              className="projectusers-dropdown-toggle"
              split
              children={
                <IoEllipsisVerticalSharp className="projectusers-dropdown-icon" />
              }
            ></MDBDropdownToggle>
            <MDBDropdownMenu>
              {userData.uid === puid || userData.role === "master" ? (
                <>
                  {userData.uid === puid && stat === "waiting" ? (
                    <MDBDropdownItem
                      preventCloseOnClick
                      className="projectusers-dropdown-item"
                      onClick={handleEdit}
                    >
                      <EditIcon /> Edit
                    </MDBDropdownItem>
                  ) : null}

                  <MDBDropdownItem
                    preventCloseOnClick
                    className="projectusers-dropdown-item"
                    onClick={handleDelete}
                  >
                    <TrashIcon /> Delete
                  </MDBDropdownItem>
                </>
              ) : userData.role === "client" ? (
                <MDBDropdownItem preventCloseOnClick>
                  <MDBBtn
                    color="danger"
                    outline
                    className="projectusers-btn"
                    onClick={handleReport}
                    disabled={hasReported}
                  >
                    <BanIcon /> {hasReported ? "Reported" : "Report"}
                  </MDBBtn>
                </MDBDropdownItem>
              ) : null}
            </MDBDropdownMenu>
          </MDBDropdown>
        ) : (
          <></>
        )}
      </MDBContainer>

      <div className="projectusers-textarea-wrapper">
        {isEditing ? (
          <>
            <MDBValidation className="row g-3" isValidated>
              <MDBValidationItem
                className="mb-3 pb-1"
                feedback="Please enter a message in the subject."
                invalid
              >
                <MDBInput
                  label="subject"
                  size="sm"
                  value={newSubject}
                  onChange={(e) => setNewSubject(e.target.value)}
                  className="projectusers-subject"
                  id="validationsubject"
                  placeholder="Required example subject"
                  required
                />
              </MDBValidationItem>
              <MDBValidationItem
                className="mb-3 pb-1"
                feedback="Please enter a message in the textarea."
                invalid
              >
                <MDBTextArea
                  label="Textarea"
                  size="sm"
                  value={newBody}
                  onChange={(e) => setNewBody(e.target.value)}
                  className="projectusers-textarea"
                  id="validationTextarea"
                  placeholder="Required example textarea"
                  required
                />
              </MDBValidationItem>
              <MDBValidationItem
                className="col-md-6"
                feedback="The minimum amount should be lower than the maximum amount."
                invalid
              >
                <MDBInput
                  type="number"
                  color="white"
                  size="sm"
                  autoComplete={false}
                  value={newMinAmount}
                  name="Min Amount"
                  id="validationCustom03"
                  className="projectusers-input"
                  required
                  max={newMaxAmount}
                  onChange={(e) => setNewMinAmount(e.target.value)}
                  label="Min Amount"
                />
              </MDBValidationItem>
              <MDBValidationItem
                className="col-md-6"
                feedback="The maximum amount should be higher than the minimum amount."
                invalid
              >
                <MDBInput
                  type="number"
                  color="white"
                  size="sm"
                  value={newMaxAmount}
                  autoComplete={false}
                  name="Max Amount"
                  id="validationCustom03"
                  className="projectusers-input"
                  required
                  min={newMinAmount}
                  onChange={(e) => setNewMaxAmount(e.target.value)}
                  label="Max Amount"
                />
              </MDBValidationItem>
            </MDBValidation>
            <div className="projectusers-btn-group">
              <MDBBtn color="success" onClick={handleSave}>
                Save
              </MDBBtn>
              <MDBBtn outline color="danger" onClick={handleCancel}>
                Cancel
              </MDBBtn>
            </div>
          </>
        ) : (
          <>
            <span className="projectusers-subject-text">{subject}</span>
            <div
              ref={textRef}
              className={
                expanded
                  ? `projectusers-body-text expanded`
                  : `projectusers-body-text`
              }
            >
              {formatTextWithLineBreaks(body)}
            </div>
          </>
        )}
        {isTruncatable && !isEditing && (
          <span className="projectusers-show-more" onClick={toggleExpanded}>
            {expanded ? "Show Less" : "Show More"}
          </span>
        )}
      </div>

      <div className="projectusers-footer">
        <div className="projectusers-amount">{`${minAmount}$ - ${maxAmount}$`}</div>
        <MDBPopover
          color={stat === "approved" ? "success" : "warning"}
          btnChildren={stat}
          placement="right"
        >
          {stat === "approved" && leader !== "" ? (
            <MDBPopoverBody className="projectusers-popover-body">
              {leader}
            </MDBPopoverBody>
          ) : (
            userData.role === "groupleader" &&
            stat === "waiting" &&
            userData.working !== true && (
              <MDBPopoverBody>
                <MDBBtn onClick={handleApprove}>Approve</MDBBtn>
              </MDBPopoverBody>
            )
          )}
        </MDBPopover>
      </div>
    </MDBContainer>
  );
}

export default Project;
