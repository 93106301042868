// src/firebase.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  sendEmailVerification,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  setDoc,
  doc,
  getDoc,
  collection,
  query,
  getDocs,
  updateDoc,
  increment,
  arrayUnion,
  addDoc,
  Timestamp,
  onSnapshot,
  limit,
  where,
} from "firebase/firestore";

// When creating a new chat
import CryptoJS from "crypto-js";
import useCurrentUser from "./currentUser/currentuser";
import { TwitterAuthProvider } from "firebase/auth/web-extension";

const firebaseConfig = {
  apiKey: "AIzaSyDPVPdYLDJZruFYbocbV9nI-uvdcfd6_rg",
  authDomain: "meetingmb-56371.firebaseapp.com",
  projectId: "meetingmb-56371",
  storageBucket: "meetingmb-56371.appspot.com",
  messagingSenderId: "1047761498906",
  appId: "1:1047761498906:web:0b68db8f7d366cbd0532a3",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const twitterProvider = new TwitterAuthProvider();

// Function to update visitor count
export const updateVisitorCountA = async () => {
  const docRef = doc(db, "stats", "visitorCount");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // If the document exists, increment the visitor count
    await updateDoc(docRef, {
      count: increment(1),
    });
  } else {
    // If the document doesn't exist, create it with an initial count of 1
    await setDoc(docRef, { count: 1 });
  }
};
export const updateVisitorCount = async () => {
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
  const docRef = doc(db, "dailyVisitors", today);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // If the document for today exists, increment the visitor count
    await updateDoc(docRef, {
      count: increment(1),
    });
  } else {
    // If the document for today doesn't exist, create it with an initial count of 1
    await setDoc(docRef, { count: 1 });
  }
};

// Generate a random AES key
export const createNewChat = async (clientId, leaderId, Pid) => {
  const encryptionKey = CryptoJS.lib.WordArray.random(16).toString(); // 128-bit key

  // Use clientId_leaderId as the document ID within the "chats" collection
  const chatDocRef = doc(db, "chats", `${clientId}_${leaderId}`);

  await setDoc(chatDocRef, {
    clientId: clientId,
    leaderId: leaderId,
    projectId: Pid,
    encryptionKey,
    active: true,
    timestamp: Timestamp.fromDate(new Date()),
  });
};

export const getUserById = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return userDoc.data();
    } else {
      console.error("No such user!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return null;
  }
};
export const getProjectById = async (projectId) => {
  try {
    const projectDocRef = doc(db, "projects", projectId);
    const projectDoc = await getDoc(projectDocRef);
    if (projectDoc.exists()) {
      return projectDoc.data();
    } else {
      console.error("No such project!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching project details: ", error);
    return null;
  }
};
// Real-time user data listener
export const getUserByIdRealTime = (userId, callback) => {
  try {
    const userDocRef = doc(db, "users", userId);
    return onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        callback(docSnapshot.data());
      } else {
        console.error("No such user!");
        callback(null);
      }
    }, (error) => {
      console.error("Error fetching user details: ", error);
      callback(null);
    });
  } catch (error) {
    console.error("Error setting real-time listener: ", error);
  }
};

// Real-time project data listener
export const getProjectByIdRealTime = (projectId, callback) => {
  try {
    const projectDocRef = doc(db, "projects", projectId);
    return onSnapshot(projectDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        callback(docSnapshot.data());
      } else {
        console.error("No such project!");
        callback(null);
      }
    }, (error) => {
      console.error("Error fetching project details: ", error);
      callback(null);
    });
  } catch (error) {
    console.error("Error setting real-time listener: ", error);
  }
};

export const setProjectById = async (projectId, fixAmount) => {
  try {
    const projectDocRef = doc(db, "projects", projectId);
    await updateDoc(projectDocRef, {
      fixAmount: fixAmount,
    });
  } catch (error) {
    console.error("Error fetching project details: ", error);
    return null;
  }
};
// Function to get visitor count
export const getVisitorCountA = async () => {
  const docRef = doc(db, "stats", "visitorCount");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().count;
  } else {
    return 0; // If the document doesn't exist, return 0
  }
};
export const getVisitorCounts = async (timeFrame) => {
  const querySnapshot = await getDocs(collection(db, "dailyVisitors"));
  const visitorData = [];

  querySnapshot.forEach((doc) => {
    visitorData.push({
      date: doc.id,
      count: doc.data().count,
    });
  });

  // Sort the data by date
  visitorData.sort((a, b) => new Date(a.date) - new Date(b.date));

  if (timeFrame === "Monthly") {
    // Group data by month
    const monthlyData = {};
    visitorData.forEach((entry) => {
      const month = entry.date.slice(0, 7); // Get "YYYY-MM"
      if (!monthlyData[month]) {
        monthlyData[month] = 0;
      }
      monthlyData[month] += entry.count;
    });
    return Object.entries(monthlyData).map(([date, count]) => ({
      date,
      count,
    }));
  }

  if (timeFrame === "Yearly") {
    // Group data by year
    const yearlyData = {};
    visitorData.forEach((entry) => {
      const year = entry.date.slice(0, 4); // Get "YYYY"
      if (!yearlyData[year]) {
        yearlyData[year] = 0;
      }
      yearlyData[year] += entry.count;
    });
    return Object.entries(yearlyData).map(([date, count]) => ({ date, count }));
  }

  // Default: Daily data
  return visitorData;
};

export const getUserCountsByRole = async () => {
  const q = query(collection(db, "users"));
  const querySnapshot = await getDocs(q);

  const roleCounts = {
    client: 0,
    programmer: 0,
    groupleader: 0,
    master: 0,
  };

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    const role = data.role;
    if (roleCounts[role] !== undefined) {
      roleCounts[role] += 1;
    }
  });

  return roleCounts;
};

const generateUniqueId = () => `conn-${Date.now()}`; // Generates a unique ID based on timestamp

export const updateUserConnection = async (userId, type) => {
  const docRef = doc(db, "users", userId);

  // Create a new entry with a unique ID and timestamp
  const newEntry = {
    id: generateUniqueId(),
    type: type,
    timestamp: new Date(), // Use local timestamp
  };

  // Overwrite the connectionHistory field with the new entry
  await updateDoc(docRef, {
    connectionHistory: [newEntry], // Replacing the entire array with the new entry
  });
};

export const getUserConnectionStatus = async (userId) => {
  try {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const userData = docSnap.data();
      const connectionHistory = userData.connectionHistory || [];
      const latestEntry = connectionHistory[connectionHistory.length - 1];

      if (latestEntry) {
        return latestEntry.type === "connect" ? "online" : "offline";
      }
    }
    return "offline";
  } catch (error) {
    console.error("Error fetching user connection status: ", error);
    return "offline";
  }
};
// Track connection and disconnection
export const trackUserConnection = () => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // User has logged in
      await updateUserConnection(user.uid, "connect");
    } else {
      // User has logged out
      // Assuming you have a way to get the current user's UID
      const currentUser = auth.currentUser;
      if (currentUser) {
        await updateUserConnection(currentUser.uid, "disconnect");
      }
    }
  });
};

export const getAllConnectedUsers = ({ onUpdate, limitCall }) => {
  let q = query(collection(db, "users"), where("role","!=","master"));
  if (limitCall) {
    q = query(q, limit(limitCall));
  }

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const connectedUsers = [];

    querySnapshot.forEach((doc) => {
      const userData = doc.data();
      const connectionHistory = userData.connectionHistory || [];
      const latestEntry = connectionHistory[connectionHistory.length - 1];

      if (latestEntry && latestEntry.type === "connect") {
        if (doc.id !== auth.currentUser.uid) {
          connectedUsers.push({
            id: doc.id,
            ...userData,
          });
        }
      }
    });

    if (onUpdate) onUpdate(connectedUsers);
  });

  return unsubscribe;
};

export const getAllUsers = async () => {
  const q = query(collection(db, "users"));
  const querySnapshot = await getDocs(q);

  const allUsers = [];

  querySnapshot.forEach((doc) => {
    const userData = doc.data();
    if (doc.id !== auth.currentUser.uid) {
      allUsers.push({
        id: doc.id,
        ...userData,
      });
    }
  });

  return allUsers;
};
export const getConnectedUserById = async (userId) => {
  const userDocRef = doc(db, "users", userId);
  const userDocSnap = await getDoc(userDocRef);

  if (userDocSnap.exists()) {
    const userData = userDocSnap.data();
    const connectionHistory = userData.connectionHistory || [];

    const latestEntry = connectionHistory[connectionHistory.length - 1];

    if (latestEntry && latestEntry.type === "connect") {
      return true;
    } else {
      return false;
    }
  }

  return null;
};

export const handleSocialSignIn = async (provider, navigate, rememberMe) => {
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    let firstName = "";
    let lastName = "";
    let birthday = "";

    if (provider === googleProvider) {
      const displayName = user.displayName
        ? user.displayName.split(" ")
        : ["", ""];
      firstName = displayName[0] || "";
      lastName = displayName[1] || "";
    } else if (provider === facebookProvider) {
      const displayName = user.displayName
        ? user.displayName.split(" ")
        : ["", ""];
      firstName = displayName[0] || "";
      lastName = displayName[1] || "";
    } else if (provider === twitterProvider) {
      const displayName = user.displayName
        ? user.displayName.split(" ")
        : ["", ""];
      firstName = displayName[0] || "";
      lastName = displayName[1] || "";
    }
    if (rememberMe) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      sessionStorage.setItem("user", JSON.stringify(user));
    }

    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const userData = docSnap.data();
      const userRole = userData.role || "client";

      if (userRole === "master") {
        navigate("/master");
      } else if (userRole === "programmer") {
        navigate("/programmer");
      } else if (userRole === "groupleader") {
        navigate("/groupleader");
      } else {
        navigate("/client");
      }
    } else {
      await setDoc(doc(db, "users", user.uid), {
        firstName: firstName,
        lastName: lastName,
        birthday: birthday,
        email: user.email,
        role: "client",
        MProjects: 0,
        AProjects: 0,
        showEmail: true,
        showPhone: true,
      });

      navigate("/client");
    }
  } catch (error) {
    console.error("Error with social login:", error.message);
  }
};

export { auth, db, googleProvider, facebookProvider, twitterProvider };
