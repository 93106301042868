import { MDBBtn } from "mdb-react-ui-kit";
import React from "react";
import { useNavigate } from "react-router-dom";

function PNotFound() {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/");
  };
  return (
    <div className="NLIDiv">
      <h1>Profile not found</h1>
      <MDBBtn color="white" outline onClick={handleGoBack}>
        Go back
      </MDBBtn>
    </div>
  );
}

export default PNotFound;
