import { sendPasswordResetEmail } from "firebase/auth";
import react, { useState } from "react";
import { auth } from "../firebase";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
} from "mdb-react-ui-kit";

export default function FPModal({ basicModal, setBasicModal }) {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent! Check your inbox.");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <MDBModal
        open={basicModal}
        onClose={() => setBasicModal(false)}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Reset Your Password</MDBModalTitle>
            </MDBModalHeader>
            {message && <p className="text-success" style={{textAlign: "center"}}>{message}</p>}
            {error && <p className="text-danger" style={{textAlign: "center"}}>{error}</p>}
            <MDBModalBody>
              <div className=".container-LR">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Email"
                />
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <button disabled={message === "" ? false : true} onClick={handlePasswordReset}>Send</button>
              <button onClick={() => setBasicModal(!basicModal)}>Close</button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
