import { Stack, Avatar } from "@chakra-ui/react";
import { User2Icon, UserCircleIcon, UserIcon } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

function AvatarD({ user }) {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate("/profile");
  };
  return (
    <Stack
      direction="row"
      onClick={() => {
        handleNav();
      }}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "flex-end",
      }}
    >
      <Avatar
        className="profile-picture"
        icon={<User2Icon fontSize="1.5rem" className="iconAvatar" />}
      />
    </Stack>
  );
}

export default AvatarD;
